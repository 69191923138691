import React, { FC, ReactNode, useState } from 'react'
import moment from 'moment'
import { DATE_PICKER_FREQUENCIES } from 'uiKit/DateControlNew/DateControl'

const getInitialStartDate = () => {
  return moment().subtract(1, 'week').add(1, 'day')
}

const DateControlContext = React.createContext({
  /**
   * @deprecated Since version 1.50.0-RELEASE. Will be deleted in future versions. Use dateRange instead
   */
  startDate: getInitialStartDate(),

  /**
   * @deprecated Since version 1.50.0-RELEASE. Will be deleted in future versions. Use dateRange instead
   */
  endDate: moment(),

  dateRange: {
    startDate: getInitialStartDate(),
    endDate: moment()
  },
  frequency: null,
  setFrequency: null,
  onChange: null,
  resetDate: null,
})

const DateControlProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [startDate, setStartDate] = useState(moment().subtract(1, 'week').add(1, 'day'))
  const [endDate, setEndDate] = useState(moment())
  const [dateRange, setDateRange] = useState({ startDate, endDate })
  const [frequency, setFrequency] = useState(DATE_PICKER_FREQUENCIES.week)

  const onChange = ({ startDate, endDate, frequency }) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setDateRange({ startDate, endDate })
    setFrequency(frequency)
  }

  const resetDate = () => {
    setStartDate(getInitialStartDate())
    setEndDate(moment())
    setDateRange({
      startDate: getInitialStartDate(),
      endDate: moment()
    })
    setFrequency(DATE_PICKER_FREQUENCIES.week)
  }

  return (
    <DateControlContext.Provider
      value={{
        startDate,
        endDate,
        dateRange,
        onChange,
        frequency,
        setFrequency,
        resetDate,
      }}>
      {children}
    </DateControlContext.Provider>
  )
}

export { DateControlProvider, DateControlContext }
