import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  background-color: var(--color-white);
  padding: 24px;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
  margin-top: 10px;
  margin-bottom: 16px;
  position: relative;
`

const Title = styled.p`
  margin: 0;
  color: var(--color-text-primary);
  font-size: 14px;
  font-weight: 700;
`

const Form = styled.div`
  display: flex;
  margin-top: 8px;
  width: 100%;
  gap: 16px;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
`

const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const SecondaryButton = styled.button`
  font-size: 14px;
  padding: 8px 16px;
  border: none;
  color: var(--color-button-primary);
  width: fit-content;
  font-weight: 500;
  background-color: var(--color-white);
  &:disabled {
    color: var(--color-text-placeholder);
  }
`

const DisableScreen = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  bottom: 0;
  opacity: 0.3;
  background-color: var(--color-selago-grey);
`

const Error = styled.div`
  width: 100%;
  font-size: 12px;
  color: var(--color-text-error);
  display: flex;
  justify-content: flex-start;
  align-content: center;
`
const SubHeader = styled.div`
  width: 100%;
  font-size: 12px;
  color: var(--color-text-secondary);
  margin-top: -10px;
  height: 5px;
  display: flex;
  justify-content: flex-end;
`

export { Container, Title, Form, DisableScreen, SecondaryButton, Error, ButtonArea, SubHeader }
