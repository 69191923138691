import React from 'react'
import { withStyles } from '@material-ui/core'

import { styles } from './styles'

interface Props {
  classes: any
  imgUrl: string
  highlight: boolean
}

const ImageMessage: React.FC<Props> = props => {
  const { classes, imgUrl, highlight } = props

  return (
    <img
      style={{ backgroundImage: '/images/platform/loader.svg',
        border: highlight && '1px solid var(--color-pressed-overlay-primary)' }}
      className={classes.img}
      src={imgUrl}
      alt="icon"
    />
  )
}

export default withStyles(styles)(ImageMessage)
