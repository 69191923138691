import React from 'react'
import { withRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import ErrorScreen from '../ErrorScreen/ErrorScreen'
import { HOME_PATH } from '../../configs'
import { connect } from 'react-redux'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ hasError: false })
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorScreen
          subtitle={'Something went wrong'}
          buttonTitle={'Back to Dashboard'}
          location={`${HOME_PATH}/bot/${this.props?.activeBot?.id}/dashboard`}
        />
      )
    } else if (this.props.isForbiddenError) {
      return (
        <ErrorScreen
          subtitle={'You do not have access to this bot'}
          buttonTitle={'Back to Bot Panel'}
          location={`${HOME_PATH}`}
        />
      )
    }

    return this.props.children
  }
}

const mapStateToProps = state => ({ activeBot: state.activeBot, isForbiddenError: state.forbiddenError.error })

export default withRouter(connect(mapStateToProps)(ErrorBoundary))
