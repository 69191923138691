import { SAVE_POPULAR_HOURS_STATISTICS } from '../actions/popularHoursStatistics'

const popularHoursStatistics = (state = null, action) => {
  if (action.type === SAVE_POPULAR_HOURS_STATISTICS) {
    return action.stats
  } else {
    return state
  }
}

export default popularHoursStatistics
