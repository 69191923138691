import styled from 'styled-components'

const LoaderWrap = styled.div`
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TextFormWrap = styled.div<{ height: number }>`
  width: 650px;
  height: ${({ height }) => height};
  position: absolute;
  background: var(--color-white);
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.086);
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`

const TitleIconWrap = styled.span`
  padding-left: 8px;
`

export { TextFormWrap, LoaderWrap, TitleIconWrap }
