import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'

const NoChatScreen = props => {
  const { classes } = props

  return (
    <div className={classes.noChatWrap}>
      <div className={classes.noChat}>
        Select a chat to start a conversation
      </div>
    </div>
  )
}

NoChatScreen.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(NoChatScreen)
