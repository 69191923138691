import React, { useEffect, useRef } from 'react'
import xss from 'xss'
import * as S from './LastMsgText.style'
import linkEditorListener from '../../helpers/linkEditorListener'
import { xssOptions } from 'constants/xssOptions'
import convertBoldText from 'helpers/convertBoldText'

interface Props {
  text: string
  isUnread: boolean
}

export const LastMsgText: React.FC<Props> = props => {
  const { text, isUnread } = props
  const output = useRef(null)

  useEffect(() => {
    linkEditorListener(output)
  }, [])

  return (
    <S.LastMsgText
      ref={output}
      isUnread={isUnread}
      dangerouslySetInnerHTML={{ __html: xss(convertBoldText(text), xssOptions) }}
    />
  )
}
