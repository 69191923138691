import styled from 'styled-components'

const Container = styled.div<{ index: number }>`
  display: flex;
  align-items: center;
  gap: 4px;
`

const Text = styled.div`
  color: var(--color-button-primary);
  font-size: 16px;
  font-weight: 700;
`

export { Container, Text }
