import { SAVE_ATOMS } from '../actions/atoms'
import getAtoms from '../helpers/getAtoms'

const atoms = (state = [], { type, payload }) => {
  if (type === SAVE_ATOMS) {
    return getAtoms(payload.flows)
  } else {
    return state
  }
}

export default atoms
