import React from 'react'
import {withStyles} from '@material-ui/core'

import {customStyles, styles} from '../LanguageSelector/styles'
import {withRouter} from 'react-router-dom'
import Select from 'react-select'
import {Reaction} from '../../tabs/nlp/api/websiteKnowledgeBase'

export const reactionOptions = [
  {
    label: 'All Reactions',
    value: Reaction.ALL,
  },
  {
    label: 'Good Reactions',
    value: Reaction.GOOD,
  },
  {
    label: 'Bad Reactions',
    value: Reaction.BAD,
  },
  {
    label: 'Without Reaction',
    value: Reaction.EMPTY,
  },
]

const ReactionsSelector = props => {
  const {onChange, value, styles} = props

  return (
    <Select
      options={reactionOptions}
      value={value}
      styles={styles || customStyles}
      isSearchable={false}
      onChange={onChange}
      components={styles}
    />
  )
}

export default withRouter(withStyles(styles)(ReactionsSelector))
