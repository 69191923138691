import { BACKEND_URL } from '../../../configs'
import {logout as logoutAction} from '../../authorization/actions/security'

export function signUpInvitedUser(user) {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json;charset=UTF-8')
  return fetch(BACKEND_URL + '/invite/user', {
    method: 'POST',
    headers: headers,
    credentials: 'include',
    body: JSON.stringify(user),
  })
}

export function signUpUser(user) {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json;charset=UTF-8')
  return fetch(BACKEND_URL + '/signUp/user', {
    method: 'POST',
    headers: headers,
    credentials: 'include',
    body: JSON.stringify(user),
  })
}

export function requestPasswordReset(email) {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json;charset=UTF-8')
  return fetch(BACKEND_URL + '/resetPassword/requestReset', {
    method: 'POST',
    headers: headers,
    credentials: 'include',
    body: JSON.stringify({ email }),
  })
}

export function validateResetPasswordToken(token) {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json;charset=UTF-8')
  return fetch(BACKEND_URL + '/resetPassword/validateToken', {
    method: 'POST',
    headers: headers,
    credentials: 'include',
    body: JSON.stringify({ token }),
  })
}

export function changeAdminPasswordWithResetToken(token, newPassword) {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json;charset=UTF-8')
  return fetch(BACKEND_URL + '/resetPassword/changePassword', {
    method: 'POST',
    headers: headers,
    credentials: 'include',
    body: JSON.stringify({ token, newPassword }),
  })
}

export function getBotInviteInfo(code) {
  return fetch(BACKEND_URL + '/invite/' + code, {
    method: 'GET',
    credentials: 'include',
  })
}

export function login(user) {
  const headers = new Headers()
  headers.append('Content-Type', 'application/x-www-form-urlencoded')
  return fetch(BACKEND_URL + '/login', {
    method: 'POST',
    credentials: 'include',
    body: new URLSearchParams({
      'email': user.username,
      'password': user.password
    }),
    headers: headers,
  })
}

export function logout() {
  return fetch(BACKEND_URL + '/logout', {
    method: 'POST',
    credentials: 'include'
  }).then(() => logoutAction())
}
