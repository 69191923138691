import { SET_AI_CHAT } from '../actions/botWidget'

const aiChatSettings = (state = null, { aiChatSettings, type }) => {
  switch (type) {
    case SET_AI_CHAT:
      return { ...aiChatSettings }
    default:
      return state
  }
}

export default aiChatSettings
