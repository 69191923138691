import { SET_ANALYTICS, SET_COMMENTS, SET_MORE_COMMENTS } from '../actions/analytics'

const initialState = {
  analytics: null,
  comments: null,
}

const analytics = (state = initialState, { type, payload }) => {
  switch (type) {
  case SET_ANALYTICS:
    return {
      ...state,
      analytics: {
        ...payload,
      },
    }

  case SET_COMMENTS:
    return {
      ...state,
      comments: {
        ...payload,
      },
    }

  case SET_MORE_COMMENTS:
    return {
      ...state,
      comments: {
        ...payload,
        feedbacks: [...state.comments.feedbacks, ...payload.feedbacks]
      },
    }

  default:
    return state
  }
}

export default analytics
