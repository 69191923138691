import React from 'react'
import {
  ReactionBarTooltip,
  ReactionBarTooltipIcons,
  ReactionBarTooltipText,
  ReactionIconContainer,
  ReactionIconWrapper
} from './ReactionBar.styles'
import { reactionCollection } from '../icons/reactionCollection'
import { ReactionType } from 'models/MessageTypes'

interface Props {
  reaction: ReactionType
  showReactionElementText: boolean
}


const ReactionIconTooltip: React.FC<Props> = ({ reaction, showReactionElementText }) => {

  return (
    <>
      {reaction.value ? (
        <ReactionIconWrapper>
          {reactionCollection[reaction.value]}
        </ReactionIconWrapper>
      ) : (
        <ReactionBarTooltip>
          {showReactionElementText && <ReactionBarTooltipText>Was this helpful?</ReactionBarTooltipText>}
          <ReactionBarTooltipIcons>
            <ReactionIconContainer>👍</ReactionIconContainer>
            <ReactionIconContainer>👎</ReactionIconContainer>
          </ReactionBarTooltipIcons>
        </ReactionBarTooltip>
      )}
    </>
  )
}

export default ReactionIconTooltip
