import { SAVE_SKILL_TYPES_STATISTICS, CLEAR_SKILL_TYPES_STATISTICS } from '../actions/skillTypeStatistics'

const skillTypesStatistics = (state = null, action) => {
  if (action.type === SAVE_SKILL_TYPES_STATISTICS) {
    return action.stats
  } else if (action.type === CLEAR_SKILL_TYPES_STATISTICS) {
    return null
  }
  return state
}

export default skillTypesStatistics
