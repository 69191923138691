import React from 'react'
import { styles } from './TooltipStyles'
import { withStyles } from '@material-ui/core'
import InfoButton from '../icons/InfoButton'
import ReactTooltip from 'react-tooltip'

const Tooltip = props => {
  const { classes, tooltipId, tooltipText } = props

  return (
    <div className={classes.tooltipWrap}>
      <InfoButton dataFor={tooltipId} dataTip={tooltipText} />
      <ReactTooltip
        className={classes.tooltip}
        effect="solid"
        place="top"
        type="dark"
        id={tooltipId}
      />
    </div>
  )
}

export default withStyles(styles)(Tooltip)
