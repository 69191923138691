import styled from 'styled-components'

const LastMsgText = styled.p<{ isUnread?: boolean }>`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 30px);
  margin: 0;
  text-decoration: none;
  color: ${({ isUnread }) => (isUnread ? 'var(--color-text-secondary)' : 'var(--color-spun-pearl-grey)')};
  font-weight: ${({ isUnread }) => (isUnread ? 'bold' : 'normal')};
  span {
    text-decoration: underline;
    cursor: pointer;
  }
`
export { LastMsgText }
