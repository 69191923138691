import React, { useEffect, useState } from 'react'
import Input from 'uiKit/inputs/Input'
import { urlValidation } from 'helpers/urlValidation'

import * as S from './WebsiteURLForm.style'
import { EnterArrow } from 'uiKit/icons/EnterArrow'
import { TrainingStatus } from '../../../../settings/constants/trainingStatus'

interface Props {
  trainingStatus: string
  urls: string[]
  onClick: (url: string) => void
}

export const WebsiteURLForm: React.FC<Props> = ({ onClick, urls, trainingStatus }) => {
  const [website, setWebsite] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    const isUnique = !urls.some(url => url === website)
    const isChildPage = urls.some(url => website.includes(url))

    if (!isUnique) {
      setError('This URL is already added')
    } else if (isChildPage) {
      setError('This address is a part of a previously added website')
    } else {
      setError('')
    }
  }, [urls])

  const handleChange = e => {
    const isUnique = !urls.some(url => url === e.target.value)
    const isValid = urlValidation(e.target.value)
    const isChildPage = urls.some(url => e.target.value.includes(url))

    if (!isValid) {
      setError('Please enter valid URL')
    } else if (!isUnique) {
      setError('This URL is already added')
    } else if (isChildPage) {
      setError('This address is a part of a previously added website')
    } else {
      setError('')
    }

    setWebsite(e.target.value)
  }

  const handleEnter = () => {
    if (!error && website) {
      onClick(website)
      setWebsite('')
    }
  }

  return (
    <S.Container>
      <S.Title>Website URL</S.Title>
      <S.Form>
        <Input
          placeholder="+Add website URL and press Enter"
          value={website}
          onChange={handleChange}
          error={Boolean(error)}
          errorMessage={error}
          handleEnterPress={handleEnter}
        />
        <S.Icon>
          <EnterArrow />
        </S.Icon>
      </S.Form>
      {trainingStatus === TrainingStatus.PENDING && <S.DisableScreen />}
    </S.Container>
  )
}
