import { SET_BOT_WIDGET, CLEAR_WIDGET_SETTINGS } from '../actions/botWidget'

const widgetSettings = (state = null, { widgetSettings, type }) => {
  switch (type) {
  case SET_BOT_WIDGET:
    return { ...widgetSettings }
  case CLEAR_WIDGET_SETTINGS:
    return null
  default:
    return state
  }
}

export default widgetSettings
