import React, {FC} from 'react'
import {AiFeedback} from '../../api/websiteKnowledgeBase'
import * as S from './ReplyFeedback.style'
import Tooltip from '../../../../uiKit/TooltipInfo/Tooltip'

interface ReplyFeedbackProps {
  aiFeedback?: AiFeedback
}

export const ReplyFeedback: FC<ReplyFeedbackProps> = ({aiFeedback}) => {
  if (aiFeedback) {
    return <S.ReactionWrap>
      {aiFeedback.isGood ? '👍' : <div>👎 <Tooltip
        tooltipId={`tooltip-reply-feedback-${aiFeedback.id}`}
        tooltipText={aiFeedback.message}
      /></div>}
    </S.ReactionWrap>
  } else {
    return <></>
  }
}
