import React, { useContext, useEffect, useState } from 'react'
import * as S from './TopUsersChart.style'
import { DateControlContext } from '../../../../contexts/DateControlContext'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import { loadTopUsers } from '../../api/dashboard'
import NoChartData from '../../../../uiKit/NoChartData'
import { connect } from 'react-redux'
import { TopUsersChartRow } from '../TopUsersChartRow/TopUsersChartRow'
import { TopUsersChartHeader } from '../TopUsersChartHeader/TopUsersChartHeader'
import { DownloadTopUsersButton } from '../DownloadTopUsersButton'

export enum SortOrder {
  // eslint-disable-next-line no-unused-vars
  ASC = 'ASC',
  // eslint-disable-next-line no-unused-vars
  DESC = 'DESC'
}

interface TopUsersChartProps {
  botId: number
  topUsersStatistics: any
}

const USERS_PER_PAGE = 10

const TopUsersChart: React.FC<TopUsersChartProps> = ({ botId, topUsersStatistics }) => {
  const [sortOrder, setSortOrder] = useState(SortOrder.DESC)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const { startDate, endDate } = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    loadTopUsers(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .finally(() => setLoading(false))
  }, [endDate])

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC)
  }

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const renderContent = () => {
    if (loading) {
      return <S.LoaderContainer>
        <LoaderSmall showLoader={true} />
      </S.LoaderContainer>
    } else {
      if (topUsersStatistics?.users.length) {
        // eslint-disable-next-line max-len
        const sortedUsers = topUsersStatistics.users.sort((d1, d2) => (sortOrder === SortOrder.ASC ? d1.numberOfMessages - d2.numberOfMessages : d2.numberOfMessages - d1.numberOfMessages))
        const startIdx = (currentPage - 1) * USERS_PER_PAGE
        const paginatedUsers = sortedUsers.slice(startIdx, startIdx + USERS_PER_PAGE)

        return <>
          <TopUsersChartHeader sortOrder={sortOrder} toggleSortOrder={toggleSortOrder} />
          <S.Line />
          <S.ScrollableTableWrapper>
            {paginatedUsers.map((d, index) => (
              <TopUsersChartRow
                key={index}
                name={d.name}
                messages={d.numberOfMessages}
                email={d.email}
                index={startIdx + index}
                sortOrder={sortOrder}
              />
            ))}
          </S.ScrollableTableWrapper>
          <S.Pagination>
            <S.PageButton
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              ←
            </S.PageButton>
            <S.PageIndicator>
              Page {currentPage} of {Math.ceil(sortedUsers.length / USERS_PER_PAGE)}
            </S.PageIndicator>
            <S.PageButton
              disabled={currentPage === Math.ceil(sortedUsers.length / USERS_PER_PAGE)}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              →
            </S.PageButton>
          </S.Pagination>
        </>
      } else {
        return <NoChartData text={'No data'} />
      }
    }
  }

  return (
    <S.Container>
      <S.Header>
        <S.Title>Users leaderboard</S.Title>
        {topUsersStatistics?.users.length && <S.Download>
          <DownloadTopUsersButton topUsersStats={topUsersStatistics?.users} />
        </S.Download>}
      </S.Header>
      <S.Line />
      <S.TableWrapper>
        {renderContent()}
      </S.TableWrapper>
    </S.Container>
  )
}

const mapStateToProps = (state: { topUsersStatistics: any }) => ({
  topUsersStatistics: state.topUsersStatistics,
})

export default connect(mapStateToProps)(TopUsersChart)
