import React, { useEffect, useRef } from 'react'
import Buttons from '../Buttons/Buttons'
import { TextMessageType } from 'models/ChatMessagesTypes'
import linkEditorListener from '../../helpers/linkEditorListener'
import convertBoldText from 'helpers/convertBoldText'

import * as S from './TextMessageButton.style'
import xss from 'xss'
import { xssOptions } from 'constants/xssOptions'

interface Props {
  message: TextMessageType
  isRtl: boolean
  highlight: boolean
  postbackIds: string[]
}

export const TextMessageButton: React.FC<Props> = props => {
  const { message, isRtl, highlight, postbackIds } = props

  const output = useRef(null)

  useEffect(() => {
    linkEditorListener(output)
  }, [])

  return (
    <S.TextMessageButton highlight={highlight}>
      <S.ChatBubble
        isRtl={isRtl}
        dangerouslySetInnerHTML={{ __html: xss(convertBoldText(message.text || message.postback), xssOptions) }}
        ref={output}
      />
      {!!message?.buttons?.length && <Buttons buttons={message?.buttons} postbackIds={postbackIds}/>}
    </S.TextMessageButton>
  )
}
