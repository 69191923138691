import styled from 'styled-components'

const Container = styled.div`
  width: 250px;
  height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(100, 136, 177, 0.25);
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.09);
`

const Value = styled.span`
  color: rgb(18, 50, 71);
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
`

const Title = styled.span`
  color: #616581;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 16px;
`

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Difference = styled.span`
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
  color: #616581;
`

const Arrow = styled.span<{ color: string }>`
  font-weight: 600;
  color: ${props => props.color};
`

export { Container, Value, Title, ValueContainer, Difference, Arrow }
