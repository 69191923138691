import React, { useEffect } from 'react'

import { HomeHeader } from './components/HomeHeader'
import { AccountSettingsForm } from './components/AccountSettingsForm'
import { setBrowserTabTitle } from '../../helpers/setBrowserTabTitle'

export const AccountContainer = props => {
  const backUrl =
    props.location && props.location.state && props.location.state.from

  useEffect(() => {
    setBrowserTabTitle('Account Settings')
  }, [])

  return (
    <React.Fragment>
      <HomeHeader
        title="Account Settings"
        backUrl={backUrl}
        backButton={true}
      />
      <AccountSettingsForm />
    </React.Fragment>
  )
}
