import { OpenRequests } from './OpenRequests'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  assignedToMeUsers: state.supportUsersNew.assignedToMeUsers,
  newRequestsUsers: state.supportUsersNew.newRequestsUsers,
  expiredRequestsUsers: state.supportUsersNew.expiredRequestsUsers,
  assignedRequestsUsers: state.supportUsersNew.assignedRequestsUsers,
  allUsers: state.supportUsersNew.allUsers,
  adminUserId: state.adminUser.id,
})

export default withRouter(connect(mapStateToProps)(OpenRequests))
