export const getLastMessage = user => {
  return user?.messagePreview ? getMessagePreview(user.messagePreview) : ''
}

const getMessagePreview = messagePreview => {
  const message = JSON.parse(messagePreview)

  if (isText(message)) return message.text
  if (isTemplate(message)) return 'Template'
  if (isAttachment(message)) return 'Attachment'
}

const isText = msg => {
  return msg.text
}

const isTemplate = msg => {
  return msg?.elements?.length
}

const isAttachment = msg => {
  return msg?.imageUrl || msg?.media?.length
}
