import styled from 'styled-components'
import { theme } from 'constants/theme'

const QuickReply = styled.button<{ isReplyPressed: boolean; isSubmitButton: boolean }>`
  margin: 2px 0px 3px 5px;
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  cursor: pointer;
  background: ${({ isReplyPressed, isSubmitButton }) =>
    (isSubmitButton
      ? 'var(--color-general-ui)'
      : isReplyPressed
        ? 'var(--color-hover-overlay-primary)'
        : theme.tabs.support.quickReply.background)};
  border: 1px solid ${({ isReplyPressed, isSubmitButton }) =>
    (isSubmitButton
      ? 'var(--color-general-ui)'
      : isReplyPressed
        ? 'var(--color-hover-overlay-primary)'
        : 'var(--color-general-ui)')};
  color: ${({ isSubmitButton }) =>
    (isSubmitButton
      ? 'var(--color-text-on-primary)'
      : 'var(--color-general-ui)')};
  box-sizing: border-box;
  border-radius: 26px;
  line-break: loose;
  user-select: none;
  line-height: 1.2;
  outline: none;
  word-break: break-word;
`

export { QuickReply }
