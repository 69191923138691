import { store } from '../../../index'

export const ADD_NEW_TAG = 'ADD_NEW_TAG'
export const SAVE_TAGS = 'SAVE_TAGS'

export const saveTags = tags => {
  store.dispatch({
    type: SAVE_TAGS,
    tags,
  })
}

export const addNewTag = tag => {
  store.dispatch({
    type: ADD_NEW_TAG,
    tag,
  })
}
