import React, { FC } from 'react'
import { TooltipProps } from '@material-ui/core/Tooltip'

import { MagicEditIcon } from 'uiKit/icons/MagicEditIcon'
import { MagicEditCompletedIcon } from 'uiKit/icons/MagicEditCompletedIcon'

import * as S from './EditTextInsert.style'

interface EditTextInsertProps {
  onClick?: (event, index: number | string) => void
  tooltipTitle?: string
  tooltipPlacement?: TooltipProps['placement']
  tooltipClasses?: Record<string, string>
  wasEdited: boolean
}

export const EditTextInsert: FC<EditTextInsertProps> = ({ onClick, wasEdited }) => {
  return (
    // <Tooltip placement={tooltipPlacement || 'top'} title={tooltipTitle || 'Edit'} classes={tooltipClasses}>
    <S.EditIconWrap onClick={onClick} wasEdited={wasEdited}>
      {wasEdited ? <MagicEditCompletedIcon /> : <MagicEditIcon />}
    </S.EditIconWrap>
    // </Tooltip>
  )
}
