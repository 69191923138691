import React, { useEffect, useState } from 'react'

import * as S from './TextTab.style'
import TextForm from '../TextForm'
import { TrainingStatus } from '../../../../settings/constants/trainingStatus'
import {
  deleteKnowledgeSources,
  getKnowledgeSources,
  trainKnowledgeSources,
  updateTextKnowledgeSources,
} from 'tabs/nlp/api/websiteKnowledgeBase'
import { alertSuccess, alertError } from 'api'
import SourcesTable from '../SourcesTable'
import { SourceTypeMap } from 'tabs/nlp/constants'

export const onTrainSuccessAlertMessage =
  'Training has successfully started. Once training is completed, we will send you an email notification'

export const TextTab = ({ activeBot, sourceId }) => {
  const [sources, setSources] = useState([])
  const [trainingStatus, setTrainingStatus] = useState(TrainingStatus.PENDING)

  useEffect(() => {
    getKnowledgeSources(activeBot.id, 'TEXT').then(res => {
      const trainingStatus = res?.sources?.some(source => source.status === TrainingStatus.PENDING)
        ? TrainingStatus.PENDING
        : TrainingStatus.READY
      setSources(res?.sources || [])
      setTrainingStatus(trainingStatus)
    })
  }, [])

  const handleAdd = async (text: string, title: string): Promise<void> => {
    const body = { text, title, type: SourceTypeMap.TEXT }

    const res = await updateTextKnowledgeSources(activeBot.id, body, false)
    setSources([...sources, res])
    alertSuccess('Text is added successfully')
  }

  const handleDelete = source => {
    const source_id = source._id
    return deleteKnowledgeSources(activeBot.id, source_id)
      .then(() => {
        const sourcesCopy = sources.filter(x => x._id !== source_id)
        setSources(sourcesCopy)
      })
      .then(() => alertSuccess('Text is deleted successfully'))
  }

  const handleTrain = () => {
    const body = sources.filter(x => ['NOT_TRAINED', 'FAILED'].includes(x.status)).map(x => x._id)
    trainKnowledgeSources(activeBot.id, body)
      .then(() => {
        setTrainingStatus(TrainingStatus.PENDING)
        alertSuccess(onTrainSuccessAlertMessage)
      })
      .catch(error => alertError(error?.message || 'Something went wrong'))
  }

  return (
    <S.TextTab>
      <TextForm onClick={handleAdd} trainingStatus={trainingStatus} />
      <SourcesTable
        sources={sources}
        setSources={setSources}
        onDelete={handleDelete}
        onTrain={handleTrain}
        trainingStatus={trainingStatus}
        sourceId={sourceId}
      />
    </S.TextTab>
  )
}
