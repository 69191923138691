import { alertError, logoutOnRequestOrResponseJson } from '../../../api'
import { BASE_URL } from '../../../configs'

export function selectedFile(file) {
  return fetch(BASE_URL + '/file', {
    credentials: 'include',
    method: 'POST',
    body: file,
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(() => {
      alertError('Sorry but something is going wrong please ping support!', 5000)
    })
}
