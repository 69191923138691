import styled from 'styled-components'

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`

const Container = styled.div`
  width: 100%;
  height: 502px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.09);
`

const Header = styled.div`
  width: 220px;
  display: flex;
  margin: 40px 40px 24px 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.span`
  color: #3A3F62;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
`

const TableRow = styled.div<{ index: number }>`
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ index }) => (index % 2 !== 0 && 'rgb(100, 136, 177, 0.25)')};
`

const IndexValue = styled.span`
  color: #3A3F62;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 24px;
`

const NameValue = styled.span`
  color: #3A3F62;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 350px;
  margin-left: 16px;
`

const MessagesValue = styled.span`
  color: #3A3F62;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 200px;
`

const EmailValue = styled.span`
  color: #3A3F62;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 350px;
`

export {
  LoaderContainer,
  Container,
  Header,
  Title,
  TableRow,
  IndexValue,
  NameValue,
  MessagesValue,
  EmailValue
}
