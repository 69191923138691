import React, { useContext, useEffect, useState } from 'react'
import { DateControlContext } from '../../../../contexts/DateControlContext'
import { loadAverageNumberOfMessages } from '../../api/dashboard'
import { CustomValueChart } from '../CustomValueChart'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import { connect } from 'react-redux'
import * as S from '../CircleChart/CircleChart.style'
import NoChartData from '../../../../uiKit/NoChartData'
import { noDataText } from '../../constants/chartsTexts'

interface AverageNumberOfMessagesCountChartProps {
  avgNumberOfMessages: any
  botId: string
}

// eslint-disable-next-line max-len
const AverageNumberOfMessagesCountChart: React.FC<AverageNumberOfMessagesCountChartProps> = ({ avgNumberOfMessages, botId }) => {

  const [loading, setLoading] = useState(false)
  const {startDate, endDate} = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    loadAverageNumberOfMessages(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .finally(() => setLoading(false))
  }, [endDate])

  const renderComponent = () => {
    if (loading) {
      return (
        <S.LoaderContainer>
          <LoaderSmall showLoader={true}/>
        </S.LoaderContainer>
      )
    } else if (avgNumberOfMessages) {
      return <CustomValueChart
        data={avgNumberOfMessages.avgNumberOfMessages}
        title={'average messages per user'}
        loading={loading}
        previousData={avgNumberOfMessages.avgNumberOfMessagesPrevious}
      />
    } else {
      return (
        <NoChartData text={noDataText} />
      )
    }
  }

  return renderComponent()
}

const mapStateToProps = (state: { avgNumberOfMessages: any }) => ({
  avgNumberOfMessages: state.avgNumberOfMessages,
})

export default connect(mapStateToProps)(AverageNumberOfMessagesCountChart)
