import React from 'react'
import { connect } from 'react-redux'

import DownloadButton from '../../../../uiKit/buttons/DownloadButton'

export const headers = [
  { label: 'General Knowledge', key: 'general' },
  { label: 'Links Knowledge', key: 'links' },
  { label: 'Products Knowledge', key: 'products' },
  { label: 'External GPT Knowledge', key: 'gpt' },
  { label: 'Inventory Status Knowledge', key: 'inventoryStatus' }
]

interface Props {
  skillTypesStatistics: any
}

const convertCircleChartDataCsv = data => {
  return [...[], data]
}

const SkillTypesCircleChartButton: React.FC<Props> = ({ skillTypesStatistics }) => {
  return (
    <DownloadButton
      csvData={skillTypesStatistics}
      csvFilename={'knowledge_usage'}
      csvHeaders={headers}
      csvOnDownload={convertCircleChartDataCsv}
    />
  )
}

const mapStateToProps = state => ({
  skillTypesStatistics: state.skillTypesStatistics,
})

export default connect(mapStateToProps)(SkillTypesCircleChartButton)
