import React, {ReactNode} from 'react'

import Tooltip from '../TooltipInfo/Tooltip'

import classes from './styles.module.scss'

interface Props {
  title: string
  tooltipText: string
  children: ReactNode
  newUsers?: number
  activeUsers?: number
  button?: ReactNode
  totalTitle?: string
  newTitle?: string
}

const DashboardContainer: React.FC<Props> = ({
  title, tooltipText, children,
  activeUsers, newUsers, button, totalTitle, newTitle,
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span className={classes.titleWrapper}>
          <div className={`${classes.text} ${classes.title}`}>
            {title}
            {tooltipText && <Tooltip tooltipId={'TooltipInfo-attribute'} tooltipText={tooltipText}/>}
          </div>
          {button}
        </span>
        {/*{!!newUsers && !!activeUsers && (*/}
        {/*  <div>*/}
        {/*    <span className={classes.statsText}>{totalTitle}</span>*/}
        {/*    <span className={classes.statsNumber}>{activeUsers}</span>*/}
        {/*    <span className={classes.statsText}>{newTitle}</span>*/}
        {/*    <span className={classes.statsNumber}>{newUsers}</span>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
      {children}
    </div>
  )
}

export default DashboardContainer
