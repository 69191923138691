import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Button from './Button'

const styles = theme => ({
  title: {
    fontSize: '18px',
    fontWeight: '500',
    margin: 0,
    color: theme.tabs.login.forgotPassword.formTitle(),
  },
  subtitle: {
    fontSize: '16px',
    color: theme.tabs.login.forgotPassword.formSubtitle(),
    marginTop: '10px',
    marginBottom: '30px',
    fontWeight: '300',
  },
})

const FormMessage = ({
  classes,
  title,
  subtitle,
  buttonTitle,
  buttonUrl,
  history,
}) => {
  const handleButtonClick = () => {
    history.push(buttonUrl)
  }

  return (
    <div>
      <h4 className={classes.title}>{title}</h4>
      <h6 className={classes.subtitle}>{subtitle}</h6>
      <Button title={buttonTitle} onClick={handleButtonClick} />
    </div>
  )
}

export default withRouter(withStyles(styles, { withTheme: true })(FormMessage))
