import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'

import { useTimer } from '../../../../hooks/useTimer'
import { EXPIRED_ICON } from '../UserDialogPreview/config'

const InteractionTimer = props => {
  const { classes, lastInteractionDate } = props
  const timerTime = useTimer(lastInteractionDate)

  const buildTimeLeft = minutes => {
    if (minutes <= 0) return ''

    const time = {
      hours: Math.floor(minutes / 60),
      minutes: minutes % 60,
    }
    const displayHours = time.hours === 0 ? '' : time.hours + 'h '
    const displayMinutes = time.minutes === 0 ? '' : time.minutes + 'm '

    return displayHours + displayMinutes + 'left'
  }

  const formattedTime = buildTimeLeft(timerTime)

  return (
    <div>
      {formattedTime === '' ? (
        <img
          src={EXPIRED_ICON}
          alt="time expired icon"
          className={classes.timeExpiredIcon}
        />
      ) : (
        <p
          className={
            timerTime >= 60
              ? classes.timeLeftContent
              : classes.timeLeftContentRed
          }>
          {formattedTime}
        </p>
      )}
    </div>
  )
}

InteractionTimer.propTypes = {
  classes: PropTypes.object,
  lastInteractionDate: PropTypes.string,
}

export default withStyles(styles)(InteractionTimer)
