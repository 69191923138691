import { store } from '../../../index'

export const SAVE_BOTS = 'SAVE_BOTS'

export const saveBots = bots => {
  store.dispatch({
    type: SAVE_BOTS,
    bots,
  })
}
