export const styles = ({ tabs: { support: { postbackModal} } }) => ({
  popover: {
    borderRadius: 10,
    height: 'auto',
    backgroundColor: postbackModal.popoverBackgroundColor,
  },
  buttonType: {
    fontFamily: 'Lato, sans-serif',
    fontSize: '12px',
    width: '290px',
    fontWeight: 400,
    textAlign: 'center',
    height: '40px',
    padding: '14px 16px 8px 16px',
    color: postbackModal.buttonType,
  },
  separator: {
    border: '1px solid',
  },
  atomName: {
    border: `1px solid ${postbackModal.atomNameBorder}`,
    color: postbackModal.primaryText,
    borderRadius: '10px',
    fontFamily: 'Lato, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'left',
    width: '258px',
    height: '40px',
    padding: '10px 16px 10px 16px',
    margin: '16px',
    overflowX: 'scroll',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      height: '2px',
    },
  },
  menuTitle: {
    color: postbackModal.menuTitleText,
    margin: '16px',
    fontFamily: 'Lato, sans-serif',
    fontSize: '12px',
    fontWeight: 700,
  },
  attributeWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
    margin: '16px',
    position: 'relative',
  },
  attributeName: {
    border: `1px solid ${postbackModal.attributeNameBorder}`,
    borderRadius: '50px',
    backgroundColor: postbackModal.attributeNameBackground,
    height: '24px',
    color: postbackModal.secondaryText,
    fontFamily: 'Lato, sans-serif',
    maxWidth: '126px',
    fontSize: '14px',
    textAlign: 'center',
    overflowX: 'scroll',
    overflowY: 'hidden',
    padding: '0px 8px 0px 8px',
    margin: '8px 0px 8px 0px',
    '&::-webkit-scrollbar': {
      height: '2px',
    },
  },
  attributeValue: {
    border: `1px solid ${postbackModal.attributeValueBorder}`,
    color: postbackModal.primaryText,
    fontFamily: 'Lato, sans-serif',
    fontSize: '14px',
    borderRadius: '10px',
    width: '126px',
    height: '40px',
    marginLeft: '8px',
    overflowX: 'scroll',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    padding: '8px',
    '&::-webkit-scrollbar': {
      height: '2px',
    },
  },
  tooltip: {
    fontFamily: 'Lato, sans-serif',
    backgroundColor: postbackModal.tooltipBackground,
    color: postbackModal.tooltipText,
    fontSize: '16px',
    borderRadius: '10px',
    padding: '8px',
  },
})
