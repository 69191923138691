import styled from 'styled-components'

const AiChatDemo = styled.div`
  height: 100vh;
  overflow: hidden;
`

const Header = styled.div`
  width: 100%;
  height: 65px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
  align-items: center;
  display: flex;
  padding: 10px;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
  z-index: 10;
`

const SecondaryButton = styled.div`
  height: 45px;
  background: white;
  border-radius: 10px;
  color: var(--color-button-primary);
  border: 1px solid var(--color-button-primary);
  min-width: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  margin-left: 32px;
`

const Body = styled.div`
  width: 100%;
  height: calc(100% - 65px);
  display: flex;
  justify-content: center;
`

const Chat = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90%;
`

export { AiChatDemo, Header, Body, Chat, SecondaryButton }
