import { store } from '../../../index'

export const SAVE_SKILL_TYPES_STATISTICS = 'SAVE_SKILL_TYPES_STATISTICS'
export const CLEAR_SKILL_TYPES_STATISTICS = 'CLEAR_SKILL_TYPES_STATISTICS'

export const saveSkillTypesStatistics = stats => {
  store.dispatch({
    type: SAVE_SKILL_TYPES_STATISTICS,
    stats,
  })
}

export const clearSkillTypesStatistics = () => {
  store.dispatch({ type: CLEAR_SKILL_TYPES_STATISTICS})
}
