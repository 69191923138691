import Modal from '@material-ui/core/Modal'
import React, { FC } from 'react'

import { CrossIcon } from 'uiKit/icons/CrossIcon'
import SubmitButton from 'uiKit/buttons/SubmitButton'

import classes from './styles.module.scss'

interface DeleteSourceModalProps {
  open: boolean
  onClose: () => void
  onDelete: (source: any) => void
}

export const DeleteSourceModal: FC<DeleteSourceModalProps> = ({ open, onClose, onDelete }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.container}>
        <p className={classes.title}>Are you sure you want to delete AI Knowledge source?</p>
        <div className={classes.closeButton} onClick={onClose}>
          <CrossIcon color={'var(--color-spun-pearl-grey)'} />
        </div>
        <div className={classes.buttonsWrap}>
          <div className={classes.deleteButton} onClick={onDelete}>
            Delete
          </div>
          <SubmitButton onClick={onClose} title="Cancel" />
        </div>
      </div>
    </Modal>
  )
}
