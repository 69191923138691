/* eslint-disable */
import React from 'react'

export const StopGeneration = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6164_25939)">
        <path d="M9.99992 5.99967H5.99992V9.99967H9.99992V5.99967Z" fill="var(--color-pale-sky)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00008 2.00033C4.68637 2.00033 2.00008 4.68662 2.00008 8.00033C2.00008 11.314 4.68637 14.0003 8.00008 14.0003C11.3138 14.0003 14.0001 11.314 14.0001 8.00033C14.0001 4.68662 11.3138 2.00033 8.00008 2.00033ZM0.666748 8.00033C0.666748 3.95024 3.94999 0.666992 8.00008 0.666992C12.0502 0.666992 15.3334 3.95024 15.3334 8.00033C15.3334 12.0504 12.0502 15.3337 8.00008 15.3337C3.94999 15.3337 0.666748 12.0504 0.666748 8.00033ZM5.33341 6.00033C5.33341 5.63214 5.63189 5.33366 6.00008 5.33366H10.0001C10.3683 5.33366 10.6667 5.63214 10.6667 6.00033V10.0003C10.6667 10.3685 10.3683 10.667 10.0001 10.667H6.00008C5.63189 10.667 5.33341 10.3685 5.33341 10.0003V6.00033ZM6.66675 6.66699V9.33366H9.33341V6.66699H6.66675Z"
          fill="var(--color-coral-red)"
        />
      </g>
      <defs>
        <clipPath id="clip0_6164_25939">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
