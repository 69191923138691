import React from 'react'

export const LoaderSmallImage: React.FC = () => {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width="40px"
    height="40px"
    viewBox="0 0 128 128"
  >
    <g>
      <path
        /* eslint-disable-next-line max-len */
        d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z"
        fill="var(--color-button-primary)"
        fillOpacity="1"
      />
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 64 64"
        to="360 64 64"
        dur="1200ms"
        repeatCount="indefinite"
      />
    </g>
  </svg>
}
