import { SAVE_USERS_STATISTICS, CLEAR_USERS_STATISTICS } from '../actions/usersStatistics'

const usersStatistics = (state = null, action) => {
  if (action.type === SAVE_USERS_STATISTICS) {
    return action.stats
  } else if (action.type === CLEAR_USERS_STATISTICS) {
    return null
  }
  return state
}

export default usersStatistics
