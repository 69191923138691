import React, { useMemo, useState } from 'react'
import { CustomValueChart } from '../CustomValueChart'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import { connect } from 'react-redux'
import * as S from '../CircleChart/CircleChart.style'
import NoChartData from '../../../../uiKit/NoChartData'
import { isObjectEmpty } from '../../../../helpers/isObjectEmpty.js'
import { usersChartDataType } from 'models/DashboardTypes'

interface TotalUsersCountChartProps {
  usersStatistics: usersChartDataType
  botId: string
}

const TotalUsersCountChart: React.FC<TotalUsersCountChartProps> = ({ usersStatistics, botId }) => {

  const [loading] = useState(false)

  const activeUsersNumber = useMemo(() => {
    if (usersStatistics?.usersBetweenDates && !isObjectEmpty(usersStatistics?.usersBetweenDates)) {
      return Object.values(usersStatistics?.usersBetweenDates).reduce((acc, count) => acc + count, 0)
    }
    return 0
  }, [usersStatistics?.usersBetweenDates])

  const activeUsersPrevious = useMemo(() => {
    if (usersStatistics?.usersBetweenDatesPrevious && !isObjectEmpty(usersStatistics?.usersBetweenDatesPrevious)) {
      return Object.values(usersStatistics?.usersBetweenDatesPrevious).reduce((acc, count) => acc + count, 0)
    }
    return 0
  }, [usersStatistics?.usersBetweenDatesPrevious])

  const renderComponent = () => {
    if (loading) {
      return (
        <S.LoaderContainer>
          <LoaderSmall showLoader={true}/>
        </S.LoaderContainer>
      )
    } else if (activeUsersNumber) {
      return <CustomValueChart
        data={activeUsersNumber}
        title={'total users'}
        loading={loading}
        previousData={activeUsersPrevious}
      />
    } else {
      return (
        <NoChartData text={'No data'} />
      )
    }
  }

  return renderComponent()
}

const mapStateToProps = (state: { usersStatistics: usersChartDataType }) => ({
  usersStatistics: state.usersStatistics
})

export default connect(mapStateToProps)(TotalUsersCountChart)
