import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'

import { dropImage } from '../../api/account'

import { EditIcon } from '../../../../uiKit/icons/Icons'

import LoaderOverlay from '../../../../uiKit/loaders/loaderOverlay'
import { acceptedFormat, baseSupportImage } from './config'

export const UserImageDropzone = props => {
  const { classes } = props
  const [attachmentImage, setAttachmentImage] = useState(props.imageUrl)
  const [isLoading, setIsLoading] = useState(false)

  const getImg = attachmentImage ? attachmentImage : baseSupportImage

  useEffect(() => {
    if (!attachmentImage) {
      return
    }

    props.setFormValue(attachmentImage)
  }, [attachmentImage])

  useEffect(() => {
    setAttachmentImage(props.imageUrl)
  }, [props.imageUrl])

  const handleDropImage = async files => {
    setIsLoading(true)
    props.handleDisableBtn(true)

    await dropImage(files).then(response => setAttachmentImage(response?.url))

    props.handleDisableBtn(false)
    setIsLoading(false)
  }

  return (
    <Dropzone
      accept={acceptedFormat}
      onDrop={handleDropImage}
      className={classes.dropzone}>
      {isLoading && <LoaderOverlay />}
      {!isLoading && <img src={getImg} alt="file" className={classes.image} />}
      <div className={classes.hoverBlock}>
        <EditIcon />
        <p>Please choose a square photo</p>
      </div>
    </Dropzone>
  )
}
