import styled from 'styled-components'

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`

const Container = styled.div`
  width: 100%;
  height: 502px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.09);
`

const Header = styled.div`
  width: 220px;
  display: flex;
  margin: 40px 40px 24px 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`


const Title = styled.span`
  color: #3A3F62;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
`


const TableHeader = styled.div`
  display: flex;
  width: 100%;
  margin-left: 52px;
  padding-bottom: 16px;
`

const NameColumnName = styled.span`
  color: #3A3F62;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  width: 350px;
`

const EmailColumnName = styled.span`
    color: #3A3F62;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    width: 350px;
`

const MessagesColumnName = styled.span`
    color: #3A3F62;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    width: 200px;
`

const TableRow = styled.div<{ index: number }>`
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ index }) => (index % 2 !== 0 && '#E9F8F2')};
`


export {
  LoaderContainer,
  Container,
  Header,
  Title,
  TableHeader,
  NameColumnName,
  EmailColumnName,
  MessagesColumnName,
  TableRow
}
