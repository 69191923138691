import { store } from '../../../index'

export const ADD_INTENT = 'ADD_INTENT'
export const CLEAR_INTENTS = 'CLEAR_INTENTS'
export const DELETE_INTENT = 'DELETE_INTENT'
export const SAVE_INTENTS = 'SAVE_INTENTS'
export const UPDATE_INTENT = 'UPDATE_INTENT'

export const addIntent = intent => {
  store.dispatch({
    type: ADD_INTENT,
    intent,
  })
}

export const clearIntents = () => {
  store.dispatch({
    type: CLEAR_INTENTS,
  })
}

export const deleteOldIntent = intentId => {
  store.dispatch({
    type: DELETE_INTENT,
    intentId,
  })
}

export const saveIntents = intents => {
  store.dispatch({
    type: SAVE_INTENTS,
    intents,
  })
}

export const updateExistedIntent = intent => {
  store.dispatch({
    type: UPDATE_INTENT,
    intent,
  })
}
