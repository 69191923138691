import React, { useState, useRef, useEffect } from 'react'
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuList } from '@material-ui/core'

import { DownArrowIcon } from 'uiKit/icons/Icons'

import { useFacebookActivePagesContext } from '../facebook/FacebookActivePages/context'
import { TestWidgetMessengerItem } from '../TestWidgetMessengerItem/TestWidgetMessengerItem'

interface Props {
  botId: number
  classes: any
}

export const TestWidgetMessengerMenu: React.FC<Props> = props => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const { pages, getPages } = useFacebookActivePagesContext()

  //TODO refactor Menu to functional component and use this useEffect in Menu
  useEffect(() => {
    getPages(props.botId)
  }, [])

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    prevOpen.current = open
  }, [open])

  return pages && pages.length > 0 ? (
    <div className={props.classes.root} ref={anchorRef}>
      <ClickAwayListener onClickAway={handleClose}>
        <Button
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={props.classes.button}>
          Test in Messenger
          <DownArrowIcon color={'var(--color-text-on-primary)'}/>
        </Button>
        <Popper
          transition
          disablePortal
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          className={props.classes.popper}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
              <Paper className={props.classes.paper}>
                <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown} className={props.classes.list}>
                  {pages.map((page, index) => (
                    <TestWidgetMessengerItem key={index} page={page} classes={props.classes} />
                  ))}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </div>
  ) : null
}
