import { store } from '../../../index'

export const SET_ATTRIBUTES = 'SET_ATTRIBUTES'
export const ADD_ATTRIBUTE = 'ADD_ATTRIBUTE'
export const CLEAR_ATTRIBUTES = 'CLEAR_ATTRIBUTES'

export const setAttributes = attributes => {
  store.dispatch({
    type: SET_ATTRIBUTES,
    payload: {
      attributes,
    },
  })
}

export const addAttribute = attribute => {
  store.dispatch({
    type: ADD_ATTRIBUTE,
    payload: {
      attribute,
    },
  })
}

export const clearAttributes = attribute => {
  store.dispatch({
    type: CLEAR_ATTRIBUTES,
    payload: {
      attribute,
    },
  })
}
