import { store } from '../../../index'

export const SAVE_BOT_ACCESSORS = 'SAVE_BOT_ACCESSORS'

export const saveBotAccessors = botAccessors => {
  store.dispatch({
    type: SAVE_BOT_ACCESSORS,
    botAccessors,
  })
}
