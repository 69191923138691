import React, { FC } from 'react'
import Button from '@material-ui/core/Button'
import LoaderSmall from 'uiKit/loaders/loaderSmall'

import { BaseButtonWrap } from './BaseButton.style'

interface BaseButtonProps {
  disabled?: boolean
  loading?: boolean
  title: string
  color?: 'primary' | 'secondary' | 'default'
  onClick: () => void
}

export const BaseButton: FC<BaseButtonProps> = ({ disabled, loading, title, onClick, color }): JSX.Element => {
  return (
    <BaseButtonWrap>
      {loading ? (
        <LoaderSmall showLoader />
      ) : (
        <Button disabled={disabled} color={color || 'default'} onClick={onClick}>
          {title}
        </Button>
      )}
    </BaseButtonWrap>
  )
}
