import React from 'react'
import ReactTooltip from 'react-tooltip'

import * as S from './FailedStatus.style'
import { AlertTriangle } from 'uiKit/icons/AlertTriangle'

export const FailedStatus = () => {
  return (
    <>
      <S.Container data-tip data-for="tooltip-failed">
        <AlertTriangle />
        <S.Text>Failed</S.Text>
      </S.Container>
      <ReactTooltip place="top" effect="solid" id="tooltip-failed">
        An error occurred during training
      </ReactTooltip>
    </>
  )
}
