import styled from 'styled-components'

const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
`

const Container = styled.div`
    width: 100%;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    background-color: white;
    border-radius: 10px;
    box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.09);
`

const ScrollableTableWrapper = styled.div`
    max-height: 320px;
    overflow-y: auto;
`

const Header = styled.div`
    width: 250px;
    display: flex;
    margin: 40px 40px 24px 40px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid #3540521A;
`

const Title = styled.span`
    color: #3A3F62;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
`

const Download = styled.span`
    cursor: pointer;
    color: #0B860B;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
`

const TableWrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: 25px;
`

const TableRow = styled.div<{ index: number }>`
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${({ index }) => (index % 2 !== 0 && '#E9F8F2')};
`

const Pagination = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0 12px 0;
`

const PageButton = styled.button`
    background-color: rgb(18, 50, 71);
    color: white;
    border: none;
    padding: 8px 16px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;

    &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }
`

const PageIndicator = styled.span`
    font-size: 14px;
    color: #3A3F62;
`

export {
  LoaderContainer,
  Container,
  ScrollableTableWrapper,
  Header,
  Line,
  Title,
  Download,
  TableWrapper,
  TableRow,
  Pagination,
  PageButton,
  PageIndicator,
}
