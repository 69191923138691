import styled from 'styled-components'

const Checkboxes = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
  flex-wrap: wrap;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-width: 380px
`

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 141px;
  justify-content: center;
`

export { Checkboxes, Container, LoaderContainer }
