import {ADD_FUNNEL, DELETE_FUNNEL, SET_FUNNELS, UPDATE_FUNNEL, CLEAR_FUNNELS_STATE} from '../actions/funnels'

const funnels = (state = [], {type, payload}) => {
  switch (type) {
  case SET_FUNNELS:
    return payload.funnels || []
  case ADD_FUNNEL:
    return [...state, payload.funnel]
  case UPDATE_FUNNEL:
    return [...state].map(funnel => {
      if (funnel.id === payload.funnel.id) funnel = payload.funnel
      return funnel
    })
  case DELETE_FUNNEL:
    return [...state].filter(({id}) => id !== payload.funnelId)
  case CLEAR_FUNNELS_STATE:
    return []
  default:
    return state
  }
}

export default funnels
