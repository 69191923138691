export const styles = () => ({
  noChatWrap: {
    flexBasis: '74%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noChat: {
    color: 'var(--color-spun-pearl-grey)',
    maxWidth: 206,
    textAlign: 'center',
  },
})
