import { SAVE_FACEBOOK_TOKEN } from '../actions/facebookToken'

const token = (state = [], action) => {
  switch (action.type) {
  case SAVE_FACEBOOK_TOKEN:
    return action.token
  default:
    return state
  }
}

export default token
