import {
  CLEAR_AVERAGE_NUMBER_OF_MESSAGES,
  SAVE_AVERAGE_NUMBER_OF_MESSAGES,
} from '../actions/averageNumberOfMessagesStatistics'

const avgNumberOfMessages = (state = null, action) => {
  if (action.type === SAVE_AVERAGE_NUMBER_OF_MESSAGES) {
    return action.stats
  } else if (action.type === CLEAR_AVERAGE_NUMBER_OF_MESSAGES) {
    return null
  }
  return state
}

export default avgNumberOfMessages
