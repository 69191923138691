import styled from 'styled-components'

const Checkboxes = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
  flex-wrap: wrap;
`

export { Checkboxes }
