import styled from 'styled-components'

const KnowledgeBaseContainer = styled.div`
  height: 100%;
`

const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
`

const DateControlWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`

const FiltersWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: flex-end;
  margin-bottom: 24px;
`

const TableTitleWithFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: self-start;
`

const SearchWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  width: 350px;

  & > div {
    width: 100%;
  }
`

const Title = styled.h1`
  font-family: Lato, serif;
  font-weight: bold;
  font-size: 24px;
  color: var(--color-text-primary);
`

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 14px;
`

const SelectorWrap = styled.span`
  width: 190px;
`

const SuccessfulReplyCountWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`

export {
  KnowledgeBaseContainer,
  TitleWrap,
  Title,
  Header,
  FiltersWrap,
  SearchWrap,
  SelectorWrap,
  DateControlWrap,
  TableTitleWithFilters,
  SuccessfulReplyCountWrap
}
