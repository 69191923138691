import React from 'react'

import {DashboardIcon, NlpIcon, SettingsIcon, SupportIcon} from '../uiKit/icons/Icons'

import Dashboard from '../tabs/dashboard/Dashboard'
import NLP from '../tabs/nlp/NlpContainer'
import ChatContainer from '../tabs/support/SupportContainer'
import Settings from '../tabs/settings/Settings'

import {ComponentNames} from '../constants/componentNames'
import {getSidebarIconColorRGB} from '../globalStylesHelper'

const sidebarIconColor = getSidebarIconColorRGB()

export const mainRoutes = [
  {
    path: 'dashboard',
    icon: <DashboardIcon width={40} height={20} color={`${sidebarIconColor}`}/>,
    name: ComponentNames.Dashboard,
    component: Dashboard,
  },
  {
    path: 'nlp',
    icon: <NlpIcon width={40} height={20} color={`${sidebarIconColor}`}/>,
    name: ComponentNames.NLP,
    component: NLP,
  },
  {
    path: 'support',
    icon: <SupportIcon width={40} height={20} color={`${sidebarIconColor}`}/>,
    name: ComponentNames.Support,
    component: ChatContainer,
  },
  {
    path: 'settings',
    icon: <SettingsIcon width={40} height={20} color={`${sidebarIconColor}`}/>,
    name: ComponentNames.BotSettings,
    component: Settings,
  },
]
