import styled from 'styled-components'

const EditIconWrap = styled.button<{ wasEdited: boolean }>`
  all: unset;

  & svg {
    ${({ wasEdited }) => wasEdited && 'height: 20px; width: 20px'};
  }
`
export { EditIconWrap }
