import { IS_AUTHENTICATED, IS_NOT_AUTHENTICATED } from '../actions/security'
import { access } from '../../../security'

const security = (state = { authenticated: false }, action) => {
  switch (action.type) {
  case IS_AUTHENTICATED:
    access.loadRole()
    localStorage.setItem('authenticated', 'true')
    return action.security
  case IS_NOT_AUTHENTICATED:
    localStorage.setItem('authenticated', 'false')
    return action.security
  default:
    return state
  }
}

export default security
