import React from 'react'
import QuickReply from '../QuickReply'
import * as S from './QuickReplyList.style'
import { QuickReplyType } from 'models/ChatMessagesTypes'

interface QuickReplyListProps {
  quickReplies: QuickReplyType[]
  postbackIds: string[]
}

const QuickReplyList: React.FC<QuickReplyListProps> = ({ quickReplies, postbackIds }) => {

  const isReplyPressed = (reply) => {
    return reply.postbackId && postbackIds.includes(reply.postbackId)
  }

  return (
    <S.QuickReplyList>
      {quickReplies?.map((reply: QuickReplyType, index: number) => (
        <div key={index}>
          <QuickReply reply={reply} isReplyPressed={isReplyPressed(reply)}/>
        </div>
      ))}
    </S.QuickReplyList>
  )
}

export default QuickReplyList
