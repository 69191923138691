import React, { useMemo } from 'react'
import { connect } from 'react-redux'

import DownloadButton from '../../../../uiKit/buttons/DownloadButton'

import { isObjectEmpty } from '../../../../helpers/isObjectEmpty'
import {usersChartDataType} from '../../../../models/DashboardTypes'

interface Props {
  usersStatistics: usersChartDataType
}

const convertAreaChartDataCsv: any = (data: usersChartDataType) => {
  return Object.keys(data.points).sort().map(k => {
    const entry = {}

    entry['date'] = k

    data.points[k].forEach(({ platform, userCount }) => {
      entry[platform] = userCount
    })

    return entry
  })
}

const AreaChartButton: React.FC<Props> = ({ usersStatistics: chartData }) => {
  const getAreaChartHeadersCsv = () => {
    const headers = [{ label: 'Date', key: 'date' }]
    const arr = Object.values(chartData.points)[0]

    if (arr && !isObjectEmpty(arr)) {
      Object.values(arr).forEach(({ platform }) => headers.push({ label: platform, key: platform }))
    }

    return headers
  }

  const csvHeaders = useMemo(() => getAreaChartHeadersCsv(), [chartData])

  return (
    <DownloadButton
      csvData={chartData}
      csvHeaders={csvHeaders}
      csvFilename={'active_users'}
      csvOnDownload={convertAreaChartDataCsv}
    />
  )
}

const mapStateToProps = state => ({
  usersStatistics: state.usersStatistics,
})

export default connect(mapStateToProps)(AreaChartButton)
