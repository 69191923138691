/**
 * Created by Admin on 02.02.2018.
 */
import {
  CLEAR_STATE,
  SET_ACTIVE_BOT,
  SET_DIALOG_FLOW,
} from '../actions/activeBot'

const activeBot = (state = null, action) => {
  let newBot
  switch (action.type) {
  case SET_ACTIVE_BOT:
    return action.activeBot
  case SET_DIALOG_FLOW:
    newBot = JSON.parse(JSON.stringify(state))
    newBot.dialogflowConfigs = action.dialogflowConfigs
    return newBot
  case CLEAR_STATE:
    return null
  default:
    return state
  }
}

export default activeBot
