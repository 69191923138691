import React from 'react'
import * as S from './CustomValueChart.style'
import LoaderSmall from 'uiKit/loaders/loaderSmall'

interface CustomValueChartProps {
  data: number
  title: string
  loading: boolean
  previousData?: number
}

export const CustomValueChart: React.FC<CustomValueChartProps> = ({
  data,
  title,
  loading,
  previousData,
}) => {

  const renderContent = () => {
    if (loading) {
      return <LoaderSmall showLoader={true}/>
    } else {
      return (
        <S.ValueContainer>
          <S.Value>{data}</S.Value>
          <S.Title>{title}</S.Title>
        </S.ValueContainer>
      )
    }
  }

  const renderDifference = () => {
    const difference = data - previousData

    let indicator
    let arrowColor
    if (difference > 0) {
      indicator = '↑'
      arrowColor = 'green'
    } else if (difference < 0) {
      indicator = '↓'
      arrowColor = 'red'
    } else {
      indicator = '-'
      arrowColor = 'black'
    }

    return (
      <S.Difference>
        <S.Arrow color={arrowColor}>{indicator}</S.Arrow>
        {' '}{Math.abs(difference)} {(' compared to the past period')}
      </S.Difference>
    )
  }

  return <S.Container>
    {renderContent()}
    {previousData !== undefined && renderDifference()}
  </S.Container>
}
