import { alertError, createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../api'
import { BASE_URL } from '../../../configs'
import { savePopularHoursStatistics } from '../actions/popularHoursStatistics'
import { saveSupportStatistics } from '../actions/supportStatistics'
import { saveUsersStatistics } from '../actions/usersStatistics'
import {saveChatsStatistics} from '../actions/chatsStatistics'
import {saveSkillTypesStatistics} from '../actions/skillTypeStatistics'
import {saveReactionsStatistics} from '../actions/reactionsStatistics'
import { saveRepeatedUsers } from '../actions/repeatedUsers'
import { saveAverageNumberOfMessages } from '../actions/averageNumberOfMessagesStatistics'
import { saveTopUsersStatistics } from '../actions/topUsersStatistics'

export const loadUsersStatistics = (botId, from, to, hourly) => {
  const loadDataUrl = !hourly
    ? `${BASE_URL}/chart/users/${botId}?from=${from}&to=${to}&allChannels=true`
    : `${BASE_URL}/chart/users/${botId}/last24hours?allChannels=true`
  return fetch(loadDataUrl, {
    method: 'GET',
    headers: createJsonHeaders(),
    credentials: 'include',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      saveUsersStatistics(responseJson.data)
    })
    .catch(err => {
      alertError('Not able to load user statistics data!')
    })
}

export const loadChatsStatistics = (botId, from, to, hourly) => {
  const loadDataUrl = `${BASE_URL}/chart/chats/${botId}?from=${from}&to=${to}&allChannels=true`
  return fetch(loadDataUrl, {
    method: 'GET',
    headers: createJsonHeaders(),
    credentials: 'include',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      saveChatsStatistics(responseJson.data)
    })
    .catch(err => {
      alertError('Not able to load user statistics data!')
    })
}

export const loadSkillTypeStatistics = (botId, from, to) => {
  const loadDataUrl = `${BASE_URL}/chart/skill/${botId}?from=${from}&to=${to}`
  return fetch(loadDataUrl, {
    method: 'GET',
    headers: createJsonHeaders(),
    credentials: 'include',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      saveSkillTypesStatistics(responseJson.data)
    })
    .catch(err => {
      alertError('Not able to load user statistics data!')
    })
}

export const loadRepeatedUsers = (botId, from, to) => {
  const loadDataUrl = `${BASE_URL}/chart/users/repeated/${botId}?from=${from}&to=${to}`
  return fetch(loadDataUrl, {
    method: 'GET',
    headers: createJsonHeaders(),
    credentials: 'include',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      saveRepeatedUsers(responseJson.data)
    })
    .catch(err => {
      alertError('Not able to load repeated users stats!')
    })
}

export const loadAverageNumberOfMessages = (botId, from, to) => {
  const loadDataUrl = `${BASE_URL}/chart/messages/average/${botId}?from=${from}&to=${to}`
  return fetch(loadDataUrl, {
    method: 'GET',
    headers: createJsonHeaders(),
    credentials: 'include',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      saveAverageNumberOfMessages(responseJson.data)
    })
    .catch(err => {
      alertError('Not able to load average number of messages stats!')
    })
}

export const loadTopUsers = (botId, from, to) => {
  const loadDataUrl = `${BASE_URL}/chart/users/leaderboard/${botId}?from=${from}&to=${to}`
  return fetch(loadDataUrl, {
    method: 'GET',
    headers: createJsonHeaders(),
    credentials: 'include',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      saveTopUsersStatistics(responseJson.data)
    })
    .catch(err => {
      alertError('Not able to load top users stats!')
    })
}

export const loadReactionsStatistics = (botId, from, to) => {
  const loadDataUrl = `${BASE_URL}/chart/reactions/${botId}?from=${from}&to=${to}`
  return fetch(loadDataUrl, {
    method: 'GET',
    headers: createJsonHeaders(),
    credentials: 'include',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      saveReactionsStatistics(responseJson.data)
    })
    .catch(err => {
      alertError('Not able to load user statistics data!')
    })
}

export const loadSupportStatistics = (botId, from, to) => {
  return fetch(`${BASE_URL}/chart/conversationCovering/${botId}?from=${from}&to=${to}`, {
    method: 'GET',
    headers: createJsonHeaders(),
    credentials: 'include',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      saveSupportStatistics(responseJson)
    })
    .catch(() => {
      alertError('Not able to load chatbot/support statistics data!')
    })
}

export const loadPopularHoursStatistics = (botId, from, to) => {
  return fetch(`${BASE_URL}/chart/hourlyConversation/${botId}?from=${from}&to=${to}`, {
    method: 'GET',
    headers: createJsonHeaders(),
    credentials: 'include',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(responseJson => {
      savePopularHoursStatistics(responseJson)
    })
    .catch(() => {
      alertError('Not able to load popular hours statistics data!')
    })
}
