import styled from 'styled-components'

const WidgetDemo = styled.div`
  height: 100vh;
  overflow: hidden;
`

const Header = styled.div`
  width: 100%;
  height: 65px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
  align-items: center;
  display: flex;
  padding: 10px;
  gap: 10px;
  justify-content: flex-end;
`

const Button = styled.div`
  height: 45px;
  background: var(--color-button-primary);
  box-shadow: rgb(19 69 186 / 21%) 0px 3px 9px;
  border-radius: 10px;
  color: var(--color-text-on-primary);
  border: none;
  min-width: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
`

const SecondaryButton = styled.div`
  height: 45px;
  background: var(--color-white);
  border-radius: 10px;
  color: var(--color-button-primary);
  border: 1px solid var(--color-button-primary);
  min-width: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  margin-left: 32px;
`

const Input = styled.input`
  height: 45px;
  padding: 16px 24px;
  outline: none;
  border-radius: 10px;
  border: 1px solid var(--color-mischka-grey);
  width: 400px;
  font-size: 14px;
`

const Body = styled.div`
  width: 100%;
  height: calc(100% - 65px);
`

const SubTitle = styled.p`
  color: var(--color-text-secondary);
  font-size: 14px;
  margin: 0px;
  max-width: 320px;
  text-align: center;
`

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 30px;
`

export { WidgetDemo, Header, Button, Input, Body, SubTitle, Iframe, Wrap, SecondaryButton }
