import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { PieChart } from 'react-chartkick'

import { noDataText } from '../../constants/chartsTexts'

import { DateControlContext } from 'contexts/DateControlContext'
import { loadSkillTypeStatistics } from '../../api/dashboard'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import NoChartData from 'uiKit/NoChartData'

import * as S from '../CircleChart/CircleChart.style'
import {getColorAnalytics} from '../../../../globalStylesHelper.js'

interface Props {
  skillTypesStatistics: any
  botId: string
}

const SkillTypesCircleChart: React.FC<Props> = ({ skillTypesStatistics, botId }) => {
  const [loading, setLoading] = useState(false)
  const { startDate, endDate } = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    loadSkillTypeStatistics(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .finally(() => setLoading(false))
  }, [endDate])

  const data = useMemo(() => skillTypesStatistics && ([
    ['General Knowledge', skillTypesStatistics.general],
    ['Links Knowledge', skillTypesStatistics.links],
    ['Products Knowledge', skillTypesStatistics.products],
    ['Inventory Status Knowledge', skillTypesStatistics.inventoryStatus],
    ['External GPT Knowledge', skillTypesStatistics.gpt],
  ]), [skillTypesStatistics])

  const RGB = getColorAnalytics()
  const colors = [`rgb(${RGB.R}, ${RGB.G}, ${RGB.B})`, '#b4735c', '#a89669', '#7c9b7f', '#c1572a']

  const renderComponent = () => {
    if (loading) {
      return (
        <S.LoaderContainer>
          <LoaderSmall showLoader={true}/>
        </S.LoaderContainer>
      )
    } else if (skillTypesStatistics) {
      return (
        <PieChart data={data} colors={colors} legend={'bottom'} />
      )
    } else {
      return (
        <NoChartData text={noDataText} />
      )
    }
  }

  return renderComponent()
}

const mapStateToProps = (state: { skillTypesStatistics: any }) => ({
  skillTypesStatistics: state.skillTypesStatistics
})

export default connect(mapStateToProps)(SkillTypesCircleChart)
