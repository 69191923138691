import { SAVE_TOP_USERS_STATISTICS } from '../actions/topUsersStatistics'

const topUsersStatistics = (state = null, action) => {
  if (action.type === SAVE_TOP_USERS_STATISTICS) {
    return action.stats
  } else {
    return state
  }
}

export default topUsersStatistics
