/* eslint-disable */
import React from 'react'

export const ButtonInfoIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.89304 0.000727043C3.47532 0.0598573 -0.0578898 3.68958 0.000718724 8.10765C0.059849 12.5238 3.68923 16.0581 8.10695 15.9993C12.524 15.9398 16.0581 12.3101 15.9993 7.89235C15.9401 3.47584 12.3104 -0.0582293 7.89304 0.000727043ZM8.77982 2.66663C9.59304 2.66663 9.83321 3.13863 9.83321 3.67793C9.83321 4.35219 9.29339 4.97549 8.37304 4.97549C7.60261 4.97549 7.23617 4.58784 7.25757 3.94767C7.25757 3.40819 7.70956 2.66663 8.77982 2.66663ZM6.69513 13C6.13948 13 5.73218 12.6629 6.12105 11.1796L6.75861 8.54922C6.86939 8.12765 6.888 7.95913 6.75861 7.95913C6.59183 7.95913 5.87044 8.25026 5.44435 8.53687L5.16644 8.08174C6.51826 6.95288 8.07287 6.29062 8.7393 6.29062C9.29495 6.29062 9.38747 6.94783 9.10991 7.95913L8.37965 10.7243C8.25026 11.2132 8.30591 11.3816 8.43495 11.3816C8.60208 11.3816 9.14834 11.1788 9.68504 10.7572L9.99999 11.1788C8.68504 12.4936 7.25044 13 6.69513 13Z" fill="var(--color-pale-sky)"/>
    </svg>
  )
}
