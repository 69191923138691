export const styles = theme => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  button: {
    padding: '8px 16px',
    margin: '0',
    fontSize: 16,
    fontFamily: "'Lato', sans-serif",
    minHeight: 0,
    minWidth: 0,
    backgroundColor: 'var(--color-button-primary)',
    boxShadow: 'rgb(19 69 186 / 21%) 0px 3px 9px',
    borderRadius: '10px',
    height: '45px',
    color: 'var(--color-text-on-primary)',
    fontWeight: 500,
    textTransform: 'none',

    '&:hover': {
      backgroundColor: 'var(--color-button-primary)',
    },

    '& svg': {
      marginLeft: 8,

      '& path': {
        stroke: 'var(--color-text-on-primary)',
      },
    },
  },
  paper: {
    marginRight: 0,
    zIndex: 15,
  },
  popper: {
    top: '100% !important',
    zIndex: 5,
    width: '100%',
    transform: 'none !important',
  },
  list: {
    padding: 0,
  },
  listItem: {
    fontSize: 14,
    '&:hover': {
      backgroundColor: 'var(--color-hover-overlay-primary)',
    },

    '& a': {
      color: 'inherit',
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  image: {
    width: 34,
    height: 34,
    margin: '12px 12px 12px 0',
    display: 'block',
    borderRadius: '50%',
  },
  pageName: {
    overflow: 'hidden',
    width: 100,
    textOverflow: 'ellipsis',
  },
  tooltip: {
    boxSizing: 'border-box',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: 10,
    zIndex: 9999,
    opacity: 1,
    textAlign: 'start',
    fontWeight: 'normal',
    padding: 10,
    backgroundColor: 'var(--color-text-secondary)'
  },
})
