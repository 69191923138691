import React from 'react'
import classes from './styles.module.scss'
import Divider from 'uiKit/Divider'
import Tooltip from 'uiKit/StyledTooltip/StyledTooltip'
import { copyValueToClipboard } from 'helpers/copyValueToClipboard'

interface Props {
  conversationId: string
}

const ConversationIdLine: React.FC<Props> = ({ conversationId }) => {
  return (
    <div className={classes.container}>
      <Divider margin={23} />
      <Tooltip classes={{ tooltip: classes.tooltip }} title='Conversation ID' placement='top'>
        <div className={classes.id} onClick={() => copyValueToClipboard(conversationId)}>ID #{conversationId}</div>
      </Tooltip>
    </div>
  )
}

export default ConversationIdLine
