/* eslint-disable max-len */
import { createGlobalStyle } from 'styled-components'
import * as C from 'colorConstants'
import { getColorAnalytics } from './globalStylesHelper'

const GlobalStyle = createGlobalStyle`
  a {
    color: var(--color-text-link);
  }
  ::placeholder {
    color: var(--color-text-placeholder);
  }
  :root {

    //general colors
    --color-title: rgb(${C.COLOR_TITLE || '217, 217, 217'});
    --color-text-primary: rgb(${C.COLOR_TEXT_PRIMARY || '58, 63, 98'});
    --color-text-secondary: rgb(${C.COLOR_TEXT_SECONDARY || '97, 101, 129'});
    --color-text-placeholder: rgb(${C.COLOR_TEXT_PLACEHOLDER || '172, 176, 192'});
    --color-text-error: rgb(${C.COLOR_TEXT_ERROR || '255, 98, 76'});
    --color-text-success: rgb(${C.COLOR_TEXT_SUCCESS || '32, 176, 56'});
    --color-text-link: rgb(${C.COLOR_TEXT_LINK || '13, 60, 171'});
    --color-input-stroke: rgb(${C.COLOR_INPUT_STROKE || '235, 235, 239'});
    --color-disabled-fill: rgb(${C.COLOR_DISABLED_FILL || '172, 176, 192'});

    //primary colors
    --color-primary: ${C.COLOR_PRIMARY || '22, 88, 243'};

    --color-button-primary: rgb(${C.COLOR_BUTTON_PRIMARY ? `${C.COLOR_BUTTON_PRIMARY}` : 'var(--color-primary)'});
    --color-general-ui: rgb(${C.COLOR_GENERAL_UI ? `${C.COLOR_GENERAL_UI}` : 'var(--color-primary)'});
    --color-sidebar: rgb(${C.COLOR_SIDEBAR ? `${C.COLOR_SIDEBAR}` : 'var(--color-primary)'});

    --color-analytics-r: ${getColorAnalytics().R};
    --color-analytics-g: ${getColorAnalytics().G};
    --color-analytics-b: ${getColorAnalytics().B};
    --color-analytics: rgb(${C.COLOR_ANALYTICS ? `${C.COLOR_ANALYTICS}` : 'var(--color-primary)'});

    --color-primary-6: rgba(var(--color-primary), 0.06);
    --color-primary-10: rgba(var(--color-primary), 0.1);
    --color-primary-20: rgba(var(--color-primary), 0.2);
    --color-primary-32: rgba(var(--color-primary), 0.32);
    --color-primary-40: rgba(var(--color-primary), 0.4);

    --color-table-row-primary: ${C.COLOR_TABLE_ROW_PRIMARY ? `rgba(${C.COLOR_TABLE_ROW_PRIMARY})` : 'var(--color-primary-6)'};
    --color-hover-overlay-primary: ${C.COLOR_HOVER_OVERLAY_PRIMARY ? `rgba(${C.COLOR_HOVER_OVERLAY_PRIMARY})` : 'var(--color-primary-10)'};
    --color-badge-primary: ${C.COLOR_BADGE_PRIMARY ? `rgba(${C.COLOR_BADGE_PRIMARY})` : 'var(--color-primary-20)'};
    --color-pressed-overlay-primary: ${C.COLOR_PRESSED_OVERLAY_PRIMARY ? `rgba(${C.COLOR_PRESSED_OVERLAY_PRIMARY})` : 'var(--color-primary-32)'};
    --color-shadow-login-primary: ${C.COLOR_SHADOW_LOGIN_PRIMARY ? `rgba(${C.COLOR_SHADOW_LOGIN_PRIMARY})` : 'var(--color-primary-40)'};

    //secondary colors
    --color-secondary: ${C.COLOR_SECONDARY || '90, 152, 247'};

    --color-button-secondary: rgb(${C.COLOR_BUTTON_SECONDARY ? `${C.COLOR_BUTTON_SECONDARY}` : 'var(--color-secondary)'});

    --color-secondary-10: rgba(var(--color-secondary), 0.1);
    --color-secondary-32: rgba(var(--color-secondary), 0.32);

    --color-hover-overlay-secondary: ${C.COLOR_HOVER_OVERLAY_SECONDARY ? `rgba(${C.COLOR_HOVER_OVERLAY_SECONDARY})` : 'var(--color-secondary-10)'};
    --color-pressed-overlay-secondary: ${C.COLOR_PRESSED_OVERLAY_SECONDARY ? `rgba(${C.COLOR_PRESSED_OVERLAY_SECONDARY})` : 'var(--color-secondary-32)'};

    //light colors
    --color-light: ${C.COLOR_LIGHT || '255, 255, 255'};

    --color-text-on-primary: rgb(${C.COLOR_TEXT_ON_PRIMARY ? `${C.COLOR_TEXT_ON_PRIMARY}` : 'var(--color-light)'});
    --color-text-on-secondary: rgb(${C.COLOR_TEXT_ON_SECONDARY ? `${C.COLOR_TEXT_ON_SECONDARY}` : 'var(--color-light)'});

    --color-light-8: rgba(var(--color-light), 0.08);
    --color-light-32: rgba(var(--color-light), 0.32);
    --color-light-40: rgba(var(--color-light), 0.4);

    --color-hover-overlay-light: ${C.COLOR_HOVER_OVERLAY_LIGHT ? `rgba(${C.COLOR_HOVER_OVERLAY_LIGHT})` : 'var(--color-light-8)'};
    --color-pressed-overlay-light: ${C.COLOR_PRESSED_OVERLAY_LIGHT ? `rgba(${C.COLOR_PRESSED_OVERLAY_LIGHT})` : 'var(--color-light-32)'};

    //dark colors
    --color-dark: ${C.COLOR_DARK || '0, 0, 0'};

    --color-dark-10: rgba(var(--color-dark), 0.1);

    --color-hover-overlay-dark: ${C.COLOR_HOVER_OVERLAY_DARK ? `rgba(${C.COLOR_HOVER_OVERLAY_DARK})` : 'var(--color-dark-10)'};
    
    //default colors
    --color-white: rgb(255, 255, 255);
    --color-egg-white: rgb(254, 242, 189);
    --color-river-bed: rgb(70, 73, 96);
    --color-brandeis-blue: rgb(13, 110, 253);
    --color-peach-schnapps: rgb(255, 220, 216);
    --color-half-dutch-white: rgb(255, 248, 219);
    --color-tropical-blue: rgb(212, 225, 255);
    --color-light-peach: rgb(255, 216, 170);
    --color-peppermint: rgb(229, 248, 226);
    --color-pale-sky: rgb(109, 118, 134);
    --color-mine-shaft: rgb(57, 57, 57);
    --color-platinum: rgb(229, 229, 229);
    --color-periwinkle: rgb(202, 223, 255);
    --color-hawkes-lue: rgb(238, 245, 254);
    --color-selago-grey: rgb(247, 250, 254);
    --color-shark-black: rgb(33, 37, 41);
    --color-athens-grey: rgb(250, 250, 251);
    --color-mischka-grey: rgb(215, 216, 223);
    --color-oxford-blue: rgb(53, 64, 82);
    --color-klein-blue: rgb(0, 48, 160);
    --color-gem-blue: rgb(59, 23, 162);
    --color-camarone-green: rgb(0, 100, 1);
    --color-malachite-green: rgb(17, 195, 20);
    --color-cinnamon-yellow: rgb(125, 68, 0);
    --color-tamarillo-red: rgb(162, 16, 16);
    --color-coral-red: rgb(255, 98, 76);
    --color-spun-pearl-grey: rgb(176, 178, 192);
    --color-ghost-grey: rgb(199, 202, 214);
    --color-issabeline-grey: rgb(235, 235, 235);
  }
`

export default GlobalStyle
