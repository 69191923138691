import React from 'react'

export const KnowledgeBaseSourceLinkIcon = (): JSX.Element => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M9.2694 2.73818C9.52439 2.47258 9.94641 2.46396 10.212 2.71895L13.1287 5.51895C13.2596 5.64466 13.3337 5.81834 13.3337 5.99987C13.3337 6.1814 13.2596 6.35508 13.1287 6.4808L10.212 9.2808C9.94641 9.53578 9.52438 9.52717 9.2694 9.26156C9.01442 8.99595 9.02303 8.57393 9.28864 8.31895L11.0099 6.66654L5.66699 6.66654C4.72061 6.66654 4.00033 7.39633 4.00033 8.23987L4.00033 12.1599C4.00033 12.5281 3.70185 12.8265 3.33366 12.8265C2.96547 12.8265 2.66699 12.5281 2.66699 12.1599L2.66699 8.23987C2.66699 6.60917 4.03605 5.3332 5.66699 5.3332L11.0099 5.3332L9.28864 3.6808C9.02303 3.42581 9.01442 3.00379 9.2694 2.73818Z"
      fill="var(--color-button-primary)"
    />
  </svg>
)
