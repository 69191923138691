import React, { useRef, useEffect } from 'react'
import xss from 'xss'

import { TextMessageType } from 'models/ChatMessagesTypes'
import * as S from './TextMessage.style'
import linkEditorListener from '../../helpers/linkEditorListener'
import convertBoldText from 'helpers/convertBoldText'
import { xssOptions } from 'constants/xssOptions'


interface Props {
  message: TextMessageType
  isReceived: boolean
  isRtl: boolean
  highlight: boolean
}

export const TextMessage: React.FC<Props> = props => {
  const { message } = props

  const output = useRef(null)

  useEffect(() => {
    linkEditorListener(output)
  }, [])

  return (
    <S.TextMessage
      ref={output}
      dangerouslySetInnerHTML={{ __html: xss(convertBoldText(message.text || message.postback), xssOptions) }}
      {...props}
    />
  )
}
