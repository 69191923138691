import { store } from '../../../index'

export const SET_ANALYTICS = 'SET_ANALYTICS'
export const SET_COMMENTS = 'SET_COMMENTS'
export const SET_MORE_COMMENTS = 'SET_MORE_COMMENTS'

export const setAnalytics = analytics =>
  store.dispatch({
    type: SET_ANALYTICS,
    payload: analytics,
  })

export const setComments = comments =>
  store.dispatch({
    type: SET_COMMENTS,
    payload: comments,
  })

export const setMoreComments = comments =>
  store.dispatch({
    type: SET_MORE_COMMENTS,
    payload: comments,
  })
