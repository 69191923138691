export type SourceTypes = 'WEBSITE' | 'FILE' | 'TEXT'
export const SourceTypeMap: Record<SourceTypes, SourceTypes> = {
  WEBSITE: 'WEBSITE',
  FILE: 'FILE',
  TEXT: 'TEXT',
}

export type KnowledgeBaseSubTabs = 'WEBSITE' | 'FILE' | 'TEXT' | 'SETTINGS'
export const KnowledgeBaseSubTabMap: Record<KnowledgeBaseSubTabs, string> = {
  WEBSITE: 'WEBSITE',
  FILE: 'FILE',
  TEXT: 'TEXT',
  SETTINGS: 'SETTINGS',
}

export const maxTextLength = 6000
export const maxTextTitleLength = 130
