export const styles = () => ({
  link: {
    color: 'var(--color-text-link)',
    fontWeight: 'normal',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 200,
    whiteSpace: 'nowrap',
    display: 'inline-block',
  },
  copyIcon: {
    display: 'inline-block',
    marginLeft: 6,
    cursor: 'pointer',
  },
  linkWrap: {
    display: 'flex',
  },
})
