/* eslint-disable */
import React from 'react'

export const UploadingIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.999674 8.33301C1.36786 8.33301 1.66634 8.63148 1.66634 8.99967V11.6663C1.66634 11.8432 1.73658 12.0127 1.8616 12.1377C1.98663 12.2628 2.1562 12.333 2.33301 12.333H11.6663C11.8432 12.333 12.0127 12.2628 12.1377 12.1377C12.2628 12.0127 12.333 11.8432 12.333 11.6663V8.99967C12.333 8.63148 12.6315 8.33301 12.9997 8.33301C13.3679 8.33301 13.6663 8.63148 13.6663 8.99967V11.6663C13.6663 12.1968 13.4556 12.7055 13.0806 13.0806C12.7055 13.4556 12.1968 13.6663 11.6663 13.6663H2.33301C1.80257 13.6663 1.29387 13.4556 0.918794 13.0806C0.543721 12.7055 0.333008 12.1968 0.333008 11.6663V8.99967C0.333008 8.63148 0.631485 8.33301 0.999674 8.33301Z"
        fill="var(--color-button-primary)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.19494 5.19494C3.45529 4.93459 3.8774 4.93459 4.13775 5.19494L6.99967 8.05687L9.8616 5.19494C10.122 4.93459 10.5441 4.93459 10.8044 5.19494C11.0648 5.45529 11.0648 5.8774 10.8044 6.13775L7.47108 9.47108C7.21073 9.73143 6.78862 9.73143 6.52827 9.47108L3.19494 6.13775C2.93459 5.8774 2.93459 5.45529 3.19494 5.19494Z"
        fill="var(--color-button-primary)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99967 0.333008C7.36786 0.333008 7.66634 0.631485 7.66634 0.999674V8.99967C7.66634 9.36786 7.36786 9.66634 6.99967 9.66634C6.63148 9.66634 6.33301 9.36786 6.33301 8.99967V0.999674C6.33301 0.631485 6.63148 0.333008 6.99967 0.333008Z"
        fill="var(--color-button-primary)"
      />
    </svg>
  )
}
