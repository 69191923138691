import React from 'react'

import Checkbox from 'uiKit/Checkbox'
import { channelColors } from '../../constants/channelColors'

import * as S from './CheckboxesWrap.style'

interface Props {
  linePoints: any
  enabledPlatforms: any
  onChange: (value: boolean, platform: string) => void
}

const CheckboxesWrap: React.FC<Props> = ({linePoints, enabledPlatforms, onChange}) => {
  return (
    <S.Checkboxes>
      {linePoints.map(platform => (
        <Checkbox
          key={platform}
          label={platform}
          checked={enabledPlatforms.includes(platform)}
          onChange={(value: boolean) => onChange(value, platform)}
          color={channelColors.get(platform)}
        />
      ))}
    </S.Checkboxes>
  )
}

export default CheckboxesWrap
