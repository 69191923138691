import React from 'react'
import DownloadButton from '../../../../uiKit/buttons/DownloadButton'

interface DownloadTopBrandsButtonProps {
  topUsersStats: any
}

export const DownloadTopUsersButton: React.FC<DownloadTopBrandsButtonProps> = ({ topUsersStats }) => {

  const prepareDataToDownload = (): any[][] => {
    return topUsersStats.map(d => {
      return [d.name, d.numberOfMessages, d.email]
    })
  }

  return <DownloadButton
    csvData={topUsersStats}
    csvFilename={'top_10_users'}
    csvHeaders={['Name', 'Messages', 'Email']}
    csvOnDownload={() => prepareDataToDownload()}
  />
}
