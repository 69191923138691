import { store } from '../../../index'

export const IS_FORBIDDEN_ERROR = 'IS_FORBIDDEN_ERROR'
export const IS_NOT_FORBIDDEN_ERROR = 'IS_NOT_FORBIDDEN_ERROR'

export const isError = () =>
  store.dispatch({
    type: IS_FORBIDDEN_ERROR,
    error: true,
  })

export const isNotError = () => {
  store.dispatch({
    type: IS_NOT_FORBIDDEN_ERROR,
    error: false
  })
}
