import styled from 'styled-components'

export const ReactionBarContainer = styled.div`
  border: 1px solid var(--color-issabeline-grey);
  background: var(--color-white);
  padding: 16px;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 690px;
  
  &:hover button {
    display: flex;
  }
`

export const ReactionBarText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-shark-black);
  margin: 0 40px 0 0;
`

export const ReactionBarTooltip = styled.div`
  display: flex;
  align-items: center;
`

export const ReactionBarTooltipText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--color-pale-sky);
  margin: 0 8px 0 0;
`

export const ReactionBarTooltipIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-white);
  box-shadow: 0 3px 6px var(--color-issabeline-grey);
  border-radius: 24px;
  padding: 8px;
`

export const ReactionIconContainer = styled.div`
  margin: 0 16px 0 0;
  height: 16px;
  font-size: 13px;

  &:last-child {
    margin: 0;
  }
`

export const DeleteReactionButton = styled.button`
  border: none;
  background-color: var(--color-white);
  padding: 12px;
  border-radius: 50%;
  display: none;
  justify-content: center;
  align-self: center;
  box-shadow: 0 3px 6px var(--color-issabeline-grey);
  position: absolute;
  top: -18px;
  right: -18px;
`

export const ReactionIconWrapper = styled.span`
  font-size: 13px;
`
