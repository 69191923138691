/**
 * Created by Admin on 26.02.2018.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const styles = theme => ({
  container: {
    display: 'flex',
    height: '100%',
  },
  navigation: {
    minWidth: '250px',
    marginRight: 80,
  },
  body: {
    minWidth: '700px',
    width: '100%',
    marginRight: 40,
  },
  rootLink: {
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: 10,
    padding: 0,
    background: theme.defaults.contrastText,
  },
  link: {
    textDecoration: 'none',
    color: theme.defaults.text,
    border: 'none',
    '&:hover>div': {
      background: 'var(--color-hover-overlay-secondary)',
    },
    '&>div': {
      height: 70,
    },
    '&:first-child > div': {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    '&:last-child>div': {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
  },
  activeLink: {
    '& span': {
      color: 'var(--color-text-on-secondary)',
    },
    '& >div': {
      background: 'var(--color-button-secondary)',
    },
    '&:hover>div': {
      background: 'var(--color-button-secondary)',
    },
  },
  child: {
    backgroundColor: 'var(--color-pressed-overlay-secondary)'
  }
})

class Submenu extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div>
        <div className={classes.container}>
          <div className={classes.navigation}>
            <List className={classes.rootLink}>
              {this.props.routes.map(route => (
                <NavLink
                  className={classes.link}
                  key={route.path}
                  activeClassName={classes.activeLink}
                  to={`${this.props.pathLink}/${route.path}`}>
                  <ListItem
                    TouchRippleProps={{classes: classes}}
                    classes={{
                      rippleVisited: {
                        backgroundColor: 'red',
                        '&$selected': {
                          backgroundColor: 'red',
                          '&:hover': {
                            backgroundColor: 'orange',
                          },
                        },
                      },
                    }}
                    button>
                    <ListItemText primary={route.name} />
                  </ListItem>
                </NavLink>
              ))}
            </List>
          </div>
          <div className={classes.body}>
            <Switch>
              {this.props.routes.map(({ path, component: Component }) => (
                <Route
                  exact
                  key={path}
                  path={`${this.props.pathRoute}${path}/:sourceType?/:sourceId?`}
                  render={props => <Component {...props} />}
                />
              ))}
              <Redirect from={this.props.pathRoute} to={`${this.props.pathLink}/${this.props.routes[0].path}`} />
            </Switch>
          </div>
        </div>
      </div>
    )
  }
}

Submenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  pathRoute: PropTypes.string.isRequired,
  pathLink: PropTypes.string,
  routes: PropTypes.array.isRequired,
}

export default withRouter(withStyles(styles, { withTheme: true })(Submenu))
