import * as ReactDOM from 'react-dom'
import { v4 as uuid } from 'uuid'

export const linkEditorListener = output => {
  // @ts-ignore
  const node = ReactDOM.findDOMNode(output.current).querySelectorAll('.linkEdited')
  const links = [...node]

  links.length > 0 &&
    links.forEach(link => {
      const url = link.getAttribute('data-href')

      link.onclick = () => window.open(url, `_newTab${uuid()}`)
    })
}
