import { store } from '../../../index'

export const SAVE_REPEATED_USERS = 'SAVE_REPEATED_USERS'
export const CLEAR_REPEATED_USERS = 'CLEAR_REPEATED_USERS'

export const saveRepeatedUsers = stats => {
  store.dispatch({
    type: SAVE_REPEATED_USERS,
    stats,
  })
}

export const clearRepeatedUsers = () => {
  store.dispatch({ type: CLEAR_REPEATED_USERS})
}
