import { store } from '../../../index'

export const SAVE_AVERAGE_NUMBER_OF_MESSAGES = 'SAVE_AVERAGE_NUMBER_OF_MESSAGES'
export const CLEAR_AVERAGE_NUMBER_OF_MESSAGES = 'CLEAR_AVERAGE_NUMBER_OF_MESSAGES'

export const saveAverageNumberOfMessages = stats => {
  store.dispatch({
    type: SAVE_AVERAGE_NUMBER_OF_MESSAGES,
    stats,
  })
}

export const clearAverageNumberOfMessages = () => {
  store.dispatch({ type: CLEAR_AVERAGE_NUMBER_OF_MESSAGES})
}
