import { store } from '../../../index'

export const SAVE_INTENTS_SIZE = 'SAVE_INTENTS_SIZE'
export const ADD_INTENT_SIZE = 'ADD_INTENT_SIZE'
export const UPDATE_INTENTS_SIZE = 'UPDATE_INTENTS_SIZE'

export const saveIntentsSize = intentsSize => {
  store.dispatch({
    type: SAVE_INTENTS_SIZE,
    intentsSize,
  })
}

export const addIntentsSize = intentsSize => {
  store.dispatch({
    type: ADD_INTENT_SIZE,
    intentsSize,
  })
}

export const deleteOldIntentsSize = intentsSize => {
  store.dispatch({
    type: UPDATE_INTENTS_SIZE,
    intentsSize,
  })
}
