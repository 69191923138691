import { EditIcon } from '../../../../uiKit/icons/Icons'

export const styles = theme => ({
  container: {
    backgroundColor: theme.tabs.accountSettings.background(),
    display: 'flex',
    flexDirection: 'column',
    padding: '80px 40px 40px',
    minHeight: '100vh',
    position: 'relative',
  },
  title: {
    color: theme.tabs.accountSettings.titleText,
    margin: '40px 0',
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '43px',
  },
  img: {
    display: 'block',
    width: 120,
    height: 120,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  form: {
    '& label': {
      margin: 0,
    },
  },
  errorMessage: {
    color: '#FF0000',
  },
  formImgItem: {
    position: 'relative',

    '&:after': {
      content: EditIcon,
      display: 'block',
      width: '100%',
      height: '100%',
    },
  },
  formItem: {
    marginTop: 16,

    '& label': {
      width: '100%',
      margin: '0 0 8px',
    },
    '& input': {
      width: 320,
    },
    "& input[type='text']": {
      background: theme.tabs.accountSettings.inputBackground,
      border: '1px solid var(--color-issabeline-grey)',
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '8px 12px',
      '&:focus': {
        outline: 'none',
        borderColor: 'var(--color-button-primary)',
      }
    },
    "& input[type='email']": {
      border: 0,
      background: 'none',
      padding: 0,
      outline: 'none',
      cursor: 'default',
    },
  },
  btnHolder: {
    marginTop: 40,
  },
  loaderWrap: {
    height: 'calc(100vh - 80px)',
  },
})
