import { store } from '../../../index'

export const CLEAR_ATOM = 'CLEAR_ATOM'
export const SAVE_ATOM = 'SAVE_ATOM'

export const clearAtom = atom => {
  store.dispatch({
    type: CLEAR_ATOM,
    atom,
  })
}

export const editAtom = atom => {
  store.dispatch({
    type: SAVE_ATOM,
    atom,
  })
}
