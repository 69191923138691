import React from 'react'

import * as S from './UploadedStatus.style'
import { DoneIcon } from 'uiKit/icons/DoneIcon'
import { UploadingIcon } from 'uiKit/icons/UploadingIcon'

export const UploadedStatus = ({ isUploaded }) => {
  return (
    <S.UploadedStatus isUploaded={isUploaded}>
      {isUploaded === false ? <UploadingIcon /> : <DoneIcon />}
      {isUploaded === false ? 'Uploading' : 'Uploaded'}
    </S.UploadedStatus>
  )
}
