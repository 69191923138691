import { store } from '../../../index'

export const IS_AUTHENTICATED = 'IS_AUTHENTICATED'
export const IS_NOT_AUTHENTICATED = 'IS_NOT_AUTHENTICATED'

export const authenticate = () =>
  store.dispatch({
    type: IS_AUTHENTICATED,
    security: {
      authenticated: true,
    },
  })

export const logout = () => {
  store.dispatch({
    type: IS_NOT_AUTHENTICATED,
    security: {
      authenticated: false,
    },
  })
}
