import React from 'react'
import Heading from 'uiKit/texts/Heading'

import * as S from './EmptyNlpScreen.style'

export const EmptyNlpScreen = () => {
  return (
    <S.EmptyNlpScreen>
      <S.Image src="/images/platform/gray-chain.svg" />
      <Heading>Connect AI agent</Heading>
      <S.Text>Connect AI agent to be able to make your bot smarter.</S.Text>
    </S.EmptyNlpScreen>
  )
}
