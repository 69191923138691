import { SAVE_FACEBOOK_PAGES } from '../actions/settingsPages'

/* eslint-disable */
const settingsPages = (state = {}, action) => {
  switch (action.type) {
    case SAVE_FACEBOOK_PAGES:
      return { facebookPages: action.facebookPages }

    default:
      return state
  }
}

export default settingsPages
