import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { RobotIcon } from '../../uiKit/icons/Robot'

import { styles } from './styles'

const ErrorScreen = props => {
  const { classes, subtitle, buttonTitle, location } = props

  const handleClick = () => {
    window.location.href = location
  }

  return (
    <div className={classes.container}>
      <RobotIcon />
      <h1 className={classes.title}>Whoops!</h1>
      <p className={classes.subtitle}>{subtitle}</p>
      <p className={classes.text}>If this problem persists, please contact our Support Team.</p>
      <button className={classes.button} onClick={handleClick}>
        {buttonTitle}
      </button>
    </div>
  )
}

ErrorScreen.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(ErrorScreen)
