import { store } from '../../../index'

export const SET_FUNNELS = 'SET_FUNNELS'
export const ADD_FUNNEL = 'ADD_FUNNEL'
export const UPDATE_FUNNEL = 'UPDATE_FUNNEL'
export const DELETE_FUNNEL = 'DELETE_FUNNEL'
export const CLEAR_FUNNELS_STATE = 'CLEAR_FUNNELS_STATE'

export const setFunnels = funnels => {
  store.dispatch({
    type: SET_FUNNELS,
    payload: {
      funnels,
    },
  })
}

export const addFunnels = funnel => {
  store.dispatch({
    type: ADD_FUNNEL,
    payload: {
      funnel,
    },
  })
}

export const updateFunnelAction = funnel => {
  store.dispatch({
    type: UPDATE_FUNNEL,
    payload: {
      funnel,
    },
  })
}

export const deleteFunnelAction = funnelId => {
  store.dispatch({
    type: DELETE_FUNNEL,
    payload: {
      funnelId,
    },
  })
}

export const clearFunnelsState = () => {
  store.dispatch({
    type: CLEAR_FUNNELS_STATE
  })
}
