export const getPostbackIds = (messages: any[]): string[] => {

  const filteredObjects = messages.filter(item => {
    if (!item.messagePreview) {
      return false
    }
    const messagePreview = JSON.parse(item.messagePreview)
    return Boolean(messagePreview.isFromUser)
  })

  const postbackIds = filteredObjects.flatMap(item => {
    const messagePreview = JSON.parse(item.messagePreview)
    return messagePreview.postbackId ? messagePreview.postbackId.split(', ') : []
  })

  return postbackIds
}
