import styled from 'styled-components'

const SearchContainer = styled.div`
  margin: 8px 12px;
`

const SectionWrap = styled.div`
  overflow-y: auto;
  height: calc(100% - 127px);
`

export { SearchContainer, SectionWrap }
