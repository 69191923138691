export const SUPPORT_ICON = '/images/platform/support-icon.svg'

export const MY_SUPPORT_ICON = '/images/platform/my-support-icon.svg'

export const EXPIRED_ICON = '/images/platform/time-expired.svg'

export const OUT_OF_WORKING_HOURS_ICON = '/images/platform/out-of-working-hours.svg'

export const SUPPORT_STATUSES = {
  OPEN: 'OPEN',
  PENDING: 'PENDING',
  ASSIGNED: 'ASSIGNED',
  CLOSED: 'CLOSED',
  EXPIRED: 'EXPIRED',
}
