/* eslint-disable */
import React from 'react'

export const SearchIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53598 17C13.6678 17 17.0173 13.6421 17.0173 9.5C17.0173 5.35786 13.6678 2 9.53598 2C5.40418 2 2.05469 5.35786 2.05469 9.5C2.05469 13.6421 5.40418 17 9.53598 17Z"
        stroke="var(--color-text-secondary)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0103 19.9998L14.8232 14.7998"
        stroke="var(--color-text-secondary)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
