import { store } from '../../../index'

export const SAVE_TOP_USERS_STATISTICS = 'SAVE_TOP_USERS_STATISTICS'
export const CLEAR_TOP_USERS_STATISTICS = 'CLEAR_TOP_USERS_STATISTICS'

export const saveTopUsersStatistics = stats => {
  store.dispatch({
    type: SAVE_TOP_USERS_STATISTICS,
    stats,
  })
}

export const clearTopUsersStatistics = () => {
  store.dispatch({ type: CLEAR_TOP_USERS_STATISTICS})
}
