import { store } from '../../../index'

export const SAVE_FACEBOOK_PAGES = 'SAVE_FACEBOOK_PAGES'

export const saveFacebookPages = facebookPages => {
  store.dispatch({
    type: SAVE_FACEBOOK_PAGES,
    facebookPages,
  })
}
