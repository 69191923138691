import { SET_WIDGET_DEFAULT_SETTINGS } from '../actions/widgetDefaultSettings'

const widgetDefaultSettings = (state = null, { widgetSettings, type }) => {
  switch (type) {
  case SET_WIDGET_DEFAULT_SETTINGS:
    return { ...widgetSettings }
  default:
    return state
  }
}

export default widgetDefaultSettings
