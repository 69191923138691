import React from 'react'

import {
  getSubscribedFacebookPages,
  setNewSubscribedFacebookPage,
  unsubscribeFacebookPage,
  editSubscribedFacebookPage,
} from '../../../../api/facebook'

export const useContextValue = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [pages, setPages] = React.useState([])

  const getPages = botId => {
    setIsLoading(true)
    return getSubscribedFacebookPages(botId).then(response => {
      return setPages(response?.data || [])
    }).finally(() => setIsLoading(false))
  }

  const setNewPage = (botId, data, FBAllPages) => {
    setIsLoading(true)
    const pageToken = FBAllPages.filter(page => page.id === data.pageId)[0]['access_token']
    return setNewSubscribedFacebookPage(botId, pageToken, data).then(res => {
      const newArrayPages = [...pages, res]
      // @ts-ignore
      setPages(newArrayPages)
    }).finally(() => setIsLoading(false))
  }

  const editFacebookPage = (botId, data) => {
    setIsLoading(true)
    return editSubscribedFacebookPage(botId, data).then(() => {
      const newPages = pages.map(page => (data.pageId === page.pageId ? data : page))
      setPages(newPages)
    }).finally(() => setIsLoading(false))
  }

  const disconnectFacebookPage = (botId, data) => {
    setIsLoading(true)
    return unsubscribeFacebookPage(botId, data)
      .then(() => {
        const newPages = pages.filter(page => page.pageId !== data.pageId)
        setPages(newPages)
      }).finally(() => setIsLoading(false))
  }

  const value = React.useMemo(
    () => ({
      pages,
      getPages,
      isLoading,
      setNewPage,
      editFacebookPage,
      disconnectFacebookPage,
    }),
    [pages, isLoading],
  )

  return {
    value,
  }
}
