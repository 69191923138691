import React, { useMemo } from 'react'
import classes from './styles.module.scss'
import { AGENT_JOINED, AGENT_LEFT, END_CONVERSATION, SESSION_EXPIRED } from '../../../../constants/widgetActionsType'

interface Props {
  action: any
}

const ActionMessage: React.FC<Props> = ({ action }) => {
  const actionText = useMemo(() => {
    if (action.type === END_CONVERSATION) {
      return 'User ended the conversation'
    } else if (action.type === AGENT_LEFT) {
      return 'Agent left the conversation'
    } else if (action.type === AGENT_JOINED) {
      return 'Agent joined the conversation'
    } else if (action.type === SESSION_EXPIRED) {
      return 'Conversation ended'
    }
  }, [])

  return <>{actionText && <div className={classes.container}>{actionText}</div>}</>
}

export default ActionMessage
