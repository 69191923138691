import { store } from '../../../index'

export const SAVE_BOT_LANGUAGES = 'SAVE_BOT_LANGUAGES'

type BotLanguage = {
  fullName: string
  id: number
  isRtl: boolean
  shortName: string
  isDefault?: boolean
}

export type BotLanguages = {
  activeLanguages: BotLanguage[]
  availableLanguages: BotLanguage[]
  defaultLanguage: BotLanguage
}

export const saveBotLanguages = (languages: BotLanguages): void => {
  store.dispatch({
    type: SAVE_BOT_LANGUAGES,
    payload: languages,
  })
}
