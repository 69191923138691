import { store } from '../../../index'

export const SAVE_ATOMS = 'SAVE_ATOMS'

export const saveAtoms = flows => {
  store.dispatch({
    type: SAVE_ATOMS,
    payload: { flows },
  })
}
