import React from 'react'

import './Loader.css'

const Loader = () => (
  <div className="Loader">
    <div className="Loader__spinner" />
  </div>
)

export default Loader
