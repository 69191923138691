import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import UserName from '../UserName/UserName'

import { styles } from './styles'

const ChatHeader = props => {
  const { classes } = props

  return (
    <div className={classes.container}>
      <UserName />
    </div>
  )
}

ChatHeader.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  adminUser: PropTypes.object,
}

const mapStateToProps = state => ({
  user: state.activeUser,
  adminUser: state.adminUser,
})

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(ChatHeader)),
)
