import { store } from '../../../index'

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR'

export const showSnackBar = snackBar => {
  requestAnimationFrame(() =>
    store.dispatch({
      type: SHOW_SNACKBAR,
      snackBar,
    }),
  )
}
