import {
  getColorGeneralUi,
  getColorGeneralUiRGB,
  getLoginSVGBottomGradientColorRGB,
} from '../../../globalStylesHelper'

const prepareColorMatrixValues = () => {
  const mainColor = getColorGeneralUiRGB()

  const r = parseInt(mainColor.r) / 255
  const g = parseInt(mainColor.g) / 255
  const b = parseInt(mainColor.b) / 255

  const matrixValues = [
    0, 0, 0, 0, r,
    0, 0, 0, 0, g,
    0, 0, 0, 0, b,
    0, 0, 0, 0.4, 0
  ]

  return matrixValues.join(' ')
}

const generalUiColor = getColorGeneralUi()
const colorMatrix = prepareColorMatrixValues()
const loginSVGBottomGradientColor = getLoginSVGBottomGradientColorRGB()

const svgContent = `
<svg width="597" height="600" viewBox="0 0 597 600" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="597" height="600">
        <path d="M0 9.99999C0 4.47714 4.47715 0 10 0H597V600H9.99999C4.47714 600 0 595.523 0 590V9.99999Z"
              fill="#F7FAFE"/>
    </mask>
    <g mask="url(#mask0)">
        <g filter="url(#filter0_d)">
            <rect width="641.965" height="957.737" rx="320.983" transform="translate(924.604 -505.339) rotate(62.6775)"
                  fill="url(#paint0_linear)"/>
        </g>
        <g filter="url(#filter1_d)">
            <rect x="1" y="1" width="706.148" height="1054.47" rx="353.074"
                  transform="translate(953.275 -557.399) rotate(62.6775)"
                  stroke="${generalUiColor}" stroke-opacity="0.32"
                  stroke-width="2" stroke-dasharray="5 5"/>
        </g>
        <circle cx="8" cy="8" r="7" transform="translate(276 357)" fill="white"
        stroke="${generalUiColor}" stroke-width="2" stroke-opacity="0.24"/>
        <g filter="url(#filter2_d)">
            <rect x="0.5" y="0.5" width="774.019" height="1155.24" rx="387.01"
                  transform="translate(982.245 -610) rotate(62.6775)" stroke="${generalUiColor}" stroke-opacity="0.18"/>
        </g>
    </g>
    <defs>
        <filter id="filter0_d" x="135.168" y="-441.885" width="1022.64" height="887.038" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 0"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="25"/>
            <feColorMatrix type="matrix" values="${colorMatrix}"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        <filter id="filter1_d" x="-8.33569" y="-578.399" width="1309.65" height="1160.07" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 0"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="11.5"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0593014 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        <filter id="filter2_d" x="-68" y="-631" width="1428.98" height="1265.27" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 0"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="11.5"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0593014 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear" x2="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(641.965 957.737) scale(907.876 1354.45) rotate(-135)">
             <stop stop-color="${loginSVGBottomGradientColor}" />
             <stop offset="1" stop-color="${generalUiColor}" />
        </linearGradient>
    </defs>
</svg>
`
export const encodedSVG = `data:image/svg+xml,${encodeURIComponent(svgContent)}`
