import React from 'react'
import {
  Popover,
  Typography,
  withStyles,
} from '@material-ui/core'
import { styles } from './styles'
import Tooltip from 'uiKit/StyledTooltip/StyledTooltip'
import { copyValueToClipboard } from 'helpers/copyValueToClipboard'
import { theme } from 'constants/theme'
import Checkbox from 'uiKit/Checkbox'

interface ButtonDetailsModalProps {
  classes: any,
  btn: {
    url?: string,
    payloadAtomName?: string,
    isWebview?: boolean,
    attributes?: {
      name: string,
      value: string,
    }[],
  },
  open: boolean,
  onClose: () => void,
  anchorEl?: any,
}

const ButtonDetailsModal: React.FC<ButtonDetailsModalProps> = (props) => {
  const {
    btn,
    open,
    onClose,
    classes,
    anchorEl
  } = props
  const { postbackModal } = theme.tabs.support
  const { checkBox } = postbackModal

  return (
    <div>
      <Popover
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            overflow: 'visible',
            borderRadius: 10,
            backgroundColor: postbackModal.popoverBackgroundColor,
          },
        }}>
        <div className={classes.popover}>
          <div>
            { props.btn.url ? (
              <Typography className={classes.buttonType}>URL</Typography>
            ) : (
              <Typography className={classes.buttonType}>POSTBACK23</Typography>
            )}
          </div>
          <div className={classes.separator}></div>
          { props.btn.payloadAtomName && (
            <>
              <Typography
                className={classes.atomName}
                onClick={() => copyValueToClipboard(props.btn.payloadAtomName)}
              >{props.btn.payloadAtomName}
              </Typography>
            </>
          )}
          { !!props.btn.url && (
            <>
              <Typography
                className={classes.atomName}
                onClick={() => copyValueToClipboard(props.btn.url)}
              >{props.btn.url}
              </Typography>
              <div style={{margin: '0px 0px 16px 16px'}}>
                <Checkbox
                  turnOffPointer={true}
                  color={checkBox.checkedIcon}
                  label="Open in Messenger"
                  checked={btn.isWebview}
                  onChange={e => e}
                />
              </div>
            </>
          )}
          {!!btn.attributes?.length &&
          <Typography className={classes.menuTitle}>Set attribute</Typography>}
          <div>
            {btn.attributes?.map((attribute, index) => (
              <div className={classes.attributeWrap} key={index}>
                <Tooltip title={attribute.name} placement='top' >
                  <div
                    className={classes.attributeName}
                    onClick={() => copyValueToClipboard(attribute.name)}
                  >{attribute.name}</div>
                </Tooltip>
                <Tooltip title={attribute.value} placement='top'>
                  <div
                    className={classes.attributeValue}
                    onClick={() => copyValueToClipboard(attribute.value)}
                  >{attribute.value}</div>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default withStyles(styles)(ButtonDetailsModal)
