/* eslint-disable max-len */
import React from 'react'

export const MagicEditCompletedIcon = (): JSX.Element => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5452_13371)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.667 2.11477C12.5069 2.11477 12.3484 2.1463 12.2005 2.20756C12.0526 2.26882 11.9183 2.35861 11.8051 2.4718L2.93027 11.3466L2.28382 13.7169L4.65412 13.0705L13.5289 4.19565C13.6421 4.08246 13.7319 3.94809 13.7932 3.8002C13.8544 3.65231 13.886 3.4938 13.886 3.33372C13.886 3.17365 13.8544 3.01514 13.7932 2.86725C13.7319 2.71936 13.6421 2.58499 13.5289 2.4718C13.4157 2.35861 13.2814 2.26882 13.1335 2.20756C12.9856 2.1463 12.8271 2.11477 12.667 2.11477ZM11.6903 0.97572C11.9999 0.847456 12.3318 0.781439 12.667 0.781439C13.0022 0.781439 13.3341 0.847456 13.6437 0.97572C13.9534 1.10398 14.2347 1.29198 14.4717 1.52899C14.7087 1.76599 14.8967 2.04735 15.025 2.35701C15.1533 2.66667 15.2193 2.99856 15.2193 3.33372C15.2193 3.66889 15.1533 4.00078 15.025 4.31044C14.8967 4.6201 14.7087 4.90146 14.4717 5.13846L5.47175 14.1385C5.38971 14.2205 5.28768 14.2797 5.17575 14.3102L1.50909 15.3102C1.27828 15.3732 1.03144 15.3076 0.86227 15.1385C0.693103 14.9693 0.627551 14.7225 0.690498 14.4916L1.6905 10.825C1.72102 10.713 1.78023 10.611 1.86227 10.529L10.8623 1.52899C11.0993 1.29198 11.3806 1.10398 11.6903 0.97572Z"
          fill="var(--color-text-success)"
        />
        <path
          d="M5.50034 1.00039C5.50034 1.39822 5.65838 1.77975 5.93968 2.06105C6.22099 2.34236 6.60252 2.50039 7.00034 2.50039C6.60252 2.50039 6.22099 2.65843 5.93968 2.93973C5.65838 3.22104 5.50034 3.60257 5.50034 4.00039C5.50034 3.60257 5.34231 3.22104 5.061 2.93973C4.7797 2.65843 4.39817 2.50039 4.00034 2.50039C4.39817 2.50039 4.7797 2.34236 5.061 2.06105C5.34231 1.77975 5.50034 1.39822 5.50034 1.00039Z"
          fill="var(--color-text-success)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.50034 0.650391C5.69364 0.650391 5.85034 0.807091 5.85034 1.00039C5.85034 1.30539 5.9715 1.5979 6.18717 1.81356C6.40284 2.02923 6.69534 2.15039 7.00034 2.15039C7.19364 2.15039 7.35034 2.30709 7.35034 2.50039C7.35034 2.69369 7.19364 2.85039 7.00034 2.85039C6.69534 2.85039 6.40284 2.97155 6.18717 3.18722C5.9715 3.40288 5.85034 3.69539 5.85034 4.00039C5.85034 4.19369 5.69364 4.35039 5.50034 4.35039C5.30704 4.35039 5.15034 4.19369 5.15034 4.00039C5.15034 3.69539 5.02918 3.40288 4.81351 3.18722C4.59785 2.97155 4.30534 2.85039 4.00034 2.85039C3.80704 2.85039 3.65034 2.69369 3.65034 2.50039C3.65034 2.30709 3.80704 2.15039 4.00034 2.15039C4.30534 2.15039 4.59785 2.02923 4.81351 1.81356C5.02918 1.5979 5.15034 1.30539 5.15034 1.00039C5.15034 0.807091 5.30704 0.650391 5.50034 0.650391ZM5.50034 2.08322C5.44287 2.16283 5.3788 2.23822 5.30849 2.30854C5.23817 2.37885 5.16278 2.44292 5.08317 2.50039C5.16278 2.55786 5.23817 2.62193 5.30849 2.69224C5.3788 2.76256 5.44287 2.83795 5.50034 2.91756C5.55781 2.83795 5.62188 2.76256 5.69219 2.69224C5.76251 2.62193 5.8379 2.55786 5.91751 2.50039C5.8379 2.44292 5.76251 2.37885 5.69219 2.30854C5.62188 2.23822 5.55781 2.16283 5.50034 2.08322Z"
          fill="var(--color-text-success)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1453 10.2026C15.4016 10.4669 15.3951 10.889 15.1308 11.1453L11.0058 15.1453C10.7472 15.396 10.3362 15.396 10.0776 15.1453L8.20257 13.3271C7.93825 13.0708 7.93175 12.6487 8.18807 12.3844C8.44438 12.1201 8.86644 12.1136 9.13076 12.3699L10.5417 13.738L14.2026 10.1881C14.4669 9.93175 14.889 9.93825 15.1453 10.2026Z"
          fill="var(--color-text-success)"
        />
      </g>
      <defs>
        <clipPath id="clip0_5452_13371">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
