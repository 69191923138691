import { GET_TWILIO_CREDENTIALS } from '../actions/twilioCredentials'

const twilioCredentials = (state = {}, action) => {
  switch (action.type) {
  case GET_TWILIO_CREDENTIALS:
    return action.twilioCredentials

  default:
    return state
  }
}

export default twilioCredentials
