/* eslint-disable */
import React from 'react'
import { theme } from 'constants/theme'

interface InfoIconProps {
  color?: string
}

export const InfoIcon: React.FC<InfoIconProps> = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke={color ? color : theme.menu.helpIcon}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.375C11.7348 15.375 11.4804 15.4804 11.2929 15.6679C11.1054 15.8554 11 16.1098 11 16.375C11 16.6402 11.1054 16.8946 11.2929 17.0821C11.4804 17.2696 11.7348 17.375 12 17.375C12.2652 17.375 12.5196 17.2696 12.7071 17.0821C12.8946 16.8946 13 16.6402 13 16.375C13 16.1098 12.8946 15.8554 12.7071 15.6679C12.5196 15.4804 12.2652 15.375 12 15.375ZM12 6.625C11.2707 6.625 10.5712 6.91473 10.0555 7.43046C9.53973 7.94618 9.25 8.64565 9.25 9.375C9.25006 9.56502 9.32224 9.74794 9.45197 9.88679C9.5817 10.0256 9.7593 10.1101 9.94888 10.123C10.1385 10.136 10.3259 10.0765 10.4733 9.95657C10.6207 9.83665 10.7171 9.66525 10.743 9.477L10.75 9.375C10.75 9.04348 10.8817 8.72554 11.1161 8.49112C11.3505 8.2567 11.6685 8.125 12 8.125C12.3315 8.125 12.6495 8.2567 12.8839 8.49112C13.1183 8.72554 13.25 9.04348 13.25 9.375C13.25 9.914 13.115 10.18 12.605 10.707L12.47 10.845C11.592 11.723 11.25 12.292 11.25 13.375C11.25 13.5739 11.329 13.7647 11.4697 13.9053C11.6103 14.046 11.8011 14.125 12 14.125C12.1989 14.125 12.3897 14.046 12.5303 13.9053C12.671 13.7647 12.75 13.5739 12.75 13.375C12.75 12.836 12.885 12.57 13.395 12.043L13.53 11.905C14.408 11.027 14.75 10.458 14.75 9.375C14.75 8.64565 14.4603 7.94618 13.9445 7.43046C13.4288 6.91473 12.7293 6.625 12 6.625Z"
        fill={color ? color : theme.menu.helpIcon}
      />
    </svg>
  )
}
