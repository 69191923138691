/* eslint-disable max-len */
export const circleChartContainerTitle = 'Conversations covered'
export const hourlyChartContainerTitle = 'Average conversations by time of day'

export const areaChartTooltipText =
  'This chart shows the number of Active chats for the selected period.'

export const skillChartTooltipText =
  'This chart shows the usage ratio of the different knowledge sources that were used to generate the answer.'

export const reactionsTooltipText =
  'This chart shows the ratio of messages with good reaction, bad reaction and without reaction.'

export const areaUsersTooltipText =
  'This chart shows the number of Active users for the selected period.'
export const conversationsCoveredTooltipText =
  'This chart shows the ratio of conversations covered only by the bot and conversations in which a user asked for human support.'

export const hourlyChartTooltipText = `This chart shows the average amount of users per hour
        who were interacting with the bot for the selected period.`

export const channelsChartTooltipText = 'The percentage of users active on each channel over the selected period.'

export const noDataText = 'You have no conversations yet. They will appear here after your chatbot will get first users'
