import React, { useState } from 'react'
import * as S from './QuickReply.style'
import ButtonDetailsModal from 'uiKit/PostbackModal/ButtonDetailsModal'
import { QuickReplyType } from 'models/ChatMessagesTypes'

interface QuickReplyProps {
  reply: QuickReplyType
  isReplyPressed: boolean
}

export const QuickReply: React.FC<QuickReplyProps> = ({ reply, isReplyPressed }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const isSubmitButton = reply.attributes ?
    reply.attributes.some(a => a.name === 'is_submit_button' && a.value === 'true') :
    false

  const handleQuickReplyClick = (event) => {
    setModalOpen(true)
    setAnchorEl(event.currentTarget)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setAnchorEl(null)
  }

  return (
    <>
      <S.QuickReply onClick={handleQuickReplyClick} isReplyPressed={isReplyPressed} isSubmitButton={isSubmitButton} >
        <span>{reply.title}</span>
      </S.QuickReply>
      {modalOpen && (
        <ButtonDetailsModal
          btn={reply}
          open={modalOpen}
          onClose={handleCloseModal}
          anchorEl={anchorEl}
        />
      )}
    </>
  )
}
