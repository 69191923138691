import { store } from '../../../index'

export const SAVE_USERS_STATISTICS = 'SAVE_USERS_STATISTICS'
export const CLEAR_USERS_STATISTICS = 'CLEAR_USERS_STATISTICS'

export const saveUsersStatistics = stats => {
  store.dispatch({
    type: SAVE_USERS_STATISTICS,
    stats,
  })
}

export const clearUsersStatistics = () => {
  store.dispatch({ type: CLEAR_USERS_STATISTICS})
}
