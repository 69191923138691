import React from 'react'
import LoaderSmall from '../loader'
import classes from './styles.module.scss'

const LoaderScreen = () => {
  return (
    <div className={classes.container}>
      <LoaderSmall showLoader={true} />
    </div>
  )
}

export default LoaderScreen
