import React from 'react'
import ReactTooltip from 'react-tooltip'

import { ArrowRotate } from 'uiKit/icons/ArrowRotate'

import * as S from './TrainingStatus.style'
import classes from './styles.module.scss'

interface Props {
  title: string
  tooltip?: string
}

export const TrainingStatus = ({ title, tooltip }: Props): JSX.Element => {
  return (
    <div className={classes.tooltip}>
      <S.Container data-tip data-for="tooltip-pending">
        <ArrowRotate />
        <S.Text>{title}</S.Text>
      </S.Container>
      {tooltip && (
        <ReactTooltip place="top" effect="solid" id="tooltip-pending">
          {tooltip}
        </ReactTooltip>
      )}
    </div>
  )
}
