import { SAVE_SUPPORT_STATISTICS } from '../actions/supportStatistics'

const supportStatistics = (state = null, action) => {
  if (action.type === SAVE_SUPPORT_STATISTICS) {
    return action.stats
  } else {
    return state
  }
}

export default supportStatistics
