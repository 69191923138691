import React from 'react'
import ReactAudioPlayer from 'react-audio-player'

interface Props {
  url: string
}

const AudioMessage: React.FC<Props> = props => {
  const { url } = props

  return <ReactAudioPlayer src={url} controls />
}

export default AudioMessage
