import { ADD_NEW_TAG, SAVE_TAGS } from '../actions/tags'

const tags = (state = [], action) => {
  let tags
  switch (action.type) {
  case SAVE_TAGS:
    return action.tags
  case ADD_NEW_TAG:
    tags = [...state]
    tags.push(action.tag)
    return tags
  default:
    return state
  }
}

export default tags
