import { store } from '../../../index'

export const SET_WIDGET_DEFAULT_SETTINGS = 'SET_WIDGET_DEFAULT_SETTINGS'

export const setWidgetDefaultSettings = widgetSettings => {
  store.dispatch({
    type: SET_WIDGET_DEFAULT_SETTINGS,
    widgetSettings,
  })
}
