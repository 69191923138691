import React, { useRef, useState } from 'react'

import { withStyles } from '@material-ui/core/styles'

import { styles } from './SliderStyles'
import NextIcon from '../icons/NextIcon'
import PrevIcon from '../icons/PrevIcon'

const Slider = props => {
  const { classes, children, itemLength } = props
  const [activeIndex, setActiveIndex] = useState(0)
  const scrollEl = useRef(0)

  const nextItem = () => {
    setActiveIndex(activeIndex + 1)
    scrollLeft(activeIndex + 1)
  }

  const prevItem = () => {
    setActiveIndex(activeIndex - 1)
    scrollLeft(activeIndex - 1)
  }

  const scrollLeft = index => {
    scrollEl.current.scrollLeft = 330 * index
  }

  return (
    <div className={classes.iconWrap}>
      {activeIndex > 0 && <PrevIcon onClick={prevItem} />}
      <div className={classes.randomWrap} ref={scrollEl}>
        {children}
      </div>
      {activeIndex < itemLength - 1 && <NextIcon onClick={nextItem} />}
    </div>
  )
}

export default withStyles(styles)(Slider)
