import React from 'react'
import * as S from './TopUsersChartRow.style'
import { SortOrder } from '../TopUsersChart'

interface TopUsersChartRowProps {
  name: string
  messages: number
  email: string
  index: number
  sortOrder: SortOrder
}

const getMedalEmoji = (index: number) => {
  switch (index) {
    case 0:
      return '🥇'
    case 1:
      return '🥈'
    case 2:
      return '🥉'
    default:
      return ''
  }
}

export const TopUsersChartRow: React.FC<TopUsersChartRowProps> = ({ name, messages, email, index, sortOrder }) => {

  return <S.TableRow index={index}>
    <S.IndexValue>
      {index + 1}.
    </S.IndexValue>
    <S.NameValue>
      {name + ' '}
      {sortOrder === SortOrder.DESC && index < 3 && <span>{getMedalEmoji(index)}</span>}
    </S.NameValue>
    <S.MessagesValue>{messages}</S.MessagesValue>
    <S.EmailValue>{email}</S.EmailValue>
  </S.TableRow>
}
