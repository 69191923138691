import React from 'react'

import { useContextValue } from './useContextValue'

const FacebookActivePagesTableContext = React.createContext({
  pages: null,
  getPages: null,
  setNewPage: null,
  isLoading: false,
  editFacebookPage: null,
  disconnectFacebookPage: null
})

const FacebookActivePagesProvider = props => {
  const { value } = useContextValue()

  return (
    <FacebookActivePagesTableContext.Provider value={value}>{props.children}</FacebookActivePagesTableContext.Provider>
  )
}

function useFacebookActivePagesContext() {
  const context = React.useContext(FacebookActivePagesTableContext)

  if (!context) {
    throw new Error('useFacebookActivePagesContext must be inside FacebookActivePagesProvider')
  }

  return context
}

export { FacebookActivePagesProvider, useFacebookActivePagesContext }
