import React from 'react'
import * as S from './TopUsersChartHeader.style'
import { TopUsersChartSortDirectionIcon } from '../TopUsersChartSortDirectionIcon'
import { SortOrder } from '../TopUsersChart'

interface TopUsersHeaderProps {
  sortOrder: SortOrder
  toggleSortOrder: () => void
}

export const TopUsersChartHeader: React.FC<TopUsersHeaderProps> = ({ sortOrder, toggleSortOrder }) => {

  return <S.TableHeader>
    <S.NameColumnName>Name</S.NameColumnName>
    <S.MessagesColumnName onClick={toggleSortOrder}>Messages <TopUsersChartSortDirectionIcon /></S.MessagesColumnName>
    <S.EmailColumnName>Email</S.EmailColumnName>
  </S.TableHeader>
}
