import { IS_FORBIDDEN_ERROR, IS_NOT_FORBIDDEN_ERROR } from '../actions/forbiddenError'

const forbiddenError = (state = { error: false }, action) => {
  switch (action.type) {
  case IS_FORBIDDEN_ERROR:
    return {
      error: action.error
    }
  case IS_NOT_FORBIDDEN_ERROR:
    return {
      error: action.error
    }
  default:
    return state
  }
}

export default forbiddenError
