import React, { useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'

import { SERVER_URL, EMBEDDED_AI_CHAT_BASE_URL } from 'configs'

import * as S from './AiChatDemo.style'

interface Props {
  match: any
}

const AiChatDemo: React.FC<Props> = ({ match }) => {
  const iFrameRef = useRef(null)
  const iframeSrc = `${EMBEDDED_AI_CHAT_BASE_URL}/?publicBotIdentifier=${match.params.botId}&backendURL=${SERVER_URL}`

  useEffect(() => {
    const activeBotName = localStorage.getItem('activeBotName')
    if (activeBotName) {
      document.title = `${activeBotName} - Test AI Chat`
    }
  }, [])

  const handleResetButtonClick = () => {
    iFrameRef.current.contentWindow.postMessage({ type: 'reset-chat-history' }, EMBEDDED_AI_CHAT_BASE_URL)
  }

  return (
    <S.AiChatDemo>
      <S.Header>
        <S.SecondaryButton onClick={handleResetButtonClick}>Reset chat</S.SecondaryButton>
      </S.Header>
      <S.Body>
        <S.Chat>
          <iframe
            className="col col-lg-8"
            style={{ border: 'none' }}
            ref={iFrameRef}
            id="botscrew-chat-frame"
            src={iframeSrc}></iframe>
        </S.Chat>
      </S.Body>
    </S.AiChatDemo>
  )
}

export default withRouter(AiChatDemo)
