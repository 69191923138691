import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Headline from 'uiKit/texts/Headline'
import Submenu from 'uiKit/submenu/Submenu'
import Loader from 'uiKit/loaders/loader'
import { settingsRoutes } from 'routes/settingsRoutes'
import { HOME_PATH } from 'configs'
import { setBrowserTabTitle } from 'helpers/setBrowserTabTitle'

interface Props {
  activeBot: any
  match: any
}

const Settings: React.FC<Props> = ({ activeBot, match }) => {
  const pathLink = `${HOME_PATH}/bot/${match.params.botId}/settings`
  const pathRoute = `${HOME_PATH}/bot/:botId/settings/`

  useEffect(() => {
    setBrowserTabTitle(`${activeBot?.name} - Bot Settings`)
  }, [activeBot])

  return (
    <>
      {activeBot ? (
        <div>
          <Headline title="Settings" />
          <Submenu
            pathLink={pathLink}
            pathRoute={pathRoute}
            routes={settingsRoutes}
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}

const mapStateToProps = (state: { activeBot: any }) => ({
  activeBot: state.activeBot,
})

export default withRouter(connect(mapStateToProps)(Settings))
