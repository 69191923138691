/* eslint-disable */
import React from 'react'

export const ButtonUrlIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14 6.66671C13.6318 6.66671 13.3333 6.36823 13.3333 6.00004C13.3333 5.63185 13.6318 5.33337 14 5.33337H18C18.3682 5.33337 18.6666 5.63185 18.6666 6.00004V10C18.6666 10.3682 18.3682 10.6667 18 10.6667C17.6318 10.6667 17.3333 10.3682 17.3333 10V7.60952L11.1381 13.8048C10.8777 14.0651 10.4556 14.0651 10.1952 13.8048C9.93489 13.5444 9.93489 13.1223 10.1952 12.862L16.3905 6.66671H14ZM7.33331 8.66671C7.1565 8.66671 6.98693 8.73694 6.86191 8.86197C6.73688 8.98699 6.66665 9.15656 6.66665 9.33337V16.6667C6.66665 16.8435 6.73688 17.0131 6.86191 17.1381C6.98693 17.2631 7.1565 17.3334 7.33331 17.3334H14.6666C14.8435 17.3334 15.013 17.2631 15.1381 17.1381C15.2631 17.0131 15.3333 16.8435 15.3333 16.6667V12.6667C15.3333 12.2985 15.6318 12 16 12C16.3682 12 16.6666 12.2985 16.6666 12.6667V16.6667C16.6666 17.1971 16.4559 17.7058 16.0809 18.0809C15.7058 18.456 15.1971 18.6667 14.6666 18.6667H7.33331C6.80288 18.6667 6.29417 18.456 5.9191 18.0809C5.54403 17.7058 5.33331 17.1971 5.33331 16.6667V9.33337C5.33331 8.80294 5.54403 8.29423 5.9191 7.91916C6.29417 7.54409 6.80288 7.33337 7.33331 7.33337H11.3333C11.7015 7.33337 12 7.63185 12 8.00004C12 8.36823 11.7015 8.66671 11.3333 8.66671H7.33331Z" fill="var(--color-general-ui)"/>
    </svg>
  )
}
