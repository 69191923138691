import { store } from '../../../index'

export const SAVE_REACTIONS_STATISTICS = 'SAVE_REACTIONS_STATISTICS'
export const CLEAR_REACTIONS_STATISTICS = 'CLEAR_REACTIONS_STATISTICS'

export const saveReactionsStatistics = stats => {
  store.dispatch({
    type: SAVE_REACTIONS_STATISTICS,
    stats,
  })
}

export const clearReactionsStatistics = () => {
  store.dispatch({ type: CLEAR_REACTIONS_STATISTICS})
}
