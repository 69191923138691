import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {IconChain} from '../../uiKit/icons/Icons'

const styles = () => ({
  main: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px',
    backgroundColor: 'var(--color-selago-grey)',
    textAlign: 'center',
  },
  imageNotFound: {
    width: '150px',
    height: '150px',
  },
  titleClass: {
    fontSize: '36px',
    lineHeight: 1,
    color: 'var(--color-text-primary)',
    margin: '64px 0 16px',
    fontWeight: 'bold',
  },
  paragraphClass: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'normal',
    margin: 0,
    color: 'var(--color-text-secondary)',
  },

  backToDashboardButton: {
    height: '54px',
    borderRadius: '10px',
    border: 'none',
    fontWeight: 300,
    lineHeight: 'normal',
    fontSize: '16px',
    textAlign: 'center',
    padding: '0 25px',
    color: 'var(--color-text-on-primary)',
    cursor: 'pointer',
    background: 'var(--color-button-primary)',
    boxShadow: '0 3px 9px rgba(19, 69, 186, 0.206267)',
    margin: '32px 0 0',
    '&:hover': {
      transform: 'scale(1.05)',
      transition: '.1s ease-in-out',
    },
  },
})

class Unavailable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirectToAdmin: false,
    }
  }

  handleGoDashboard = () => {
    this.setState({ redirectToAdmin: true })
  }

  render() {
    const { classes } = this.props
    if (this.state.redirectToAdmin) {
      return <Redirect to="/admin" />
    }
    return (
      <main className={classes.main}>
        <IconChain/>

        <h1 className={classes.titleClass}>
          Sorry, this link is not available anymore
        </h1>

        {this.props.linkExpired !== true && (
          <p className={classes.paragraphClass}>
            You've already been invited to the Enterprise Platform.
            Enjoy!
          </p>
        )}

        <button
          className={classes.backToDashboardButton}
          type="button"
          onClick={this.handleGoDashboard}>
          Back to Dashboard
        </button>
      </main>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Unavailable)
