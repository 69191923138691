import { BotLanguages, SAVE_BOT_LANGUAGES } from '../actions/botLanguages'

export const botLanguages = (
  state = null,
  action: { type: string; payload: BotLanguages[] },
): BotLanguages[] | null => {
  switch (action.type) {
    case SAVE_BOT_LANGUAGES:
      return action.payload

    default:
      return state
  }
}
