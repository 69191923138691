import styled from 'styled-components'

const HeadlineWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  align-items: baseline;
  gap: 40px;
  margin-right: 40px;
  min-width: 1090px;
`

const Container = styled.div`
  min-width: fit-content;
`

export { HeadlineWrap, Container }
