import { store } from '../../../index'

export const SAVE_POPULAR_HOURS_STATISTICS = 'SAVE_POPULAR_HOURS_STATISTICS'

export const savePopularHoursStatistics = stats => {
  store.dispatch({
    type: SAVE_POPULAR_HOURS_STATISTICS,
    stats,
  })
}
