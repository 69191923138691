import React, { useState } from 'react'
import { CustomValueChart } from '../CustomValueChart'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import { connect } from 'react-redux'
import * as S from '../CircleChart/CircleChart.style'
import NoChartData from '../../../../uiKit/NoChartData'

interface NewUsersCountChartProps {
  usersStatistics: any
  botId: string
}

const NewUsersCountChart: React.FC<NewUsersCountChartProps> = ({ usersStatistics, botId }) => {

  const [loading] = useState(false)

  const renderComponent = () => {
    if (loading) {
      return (
        <S.LoaderContainer>
          <LoaderSmall showLoader={true}/>
        </S.LoaderContainer>
      )
    } else if (usersStatistics?.newUsers) {
      return <CustomValueChart
        data={usersStatistics?.newUsers}
        title={'new users'}
        loading={loading}
        previousData={usersStatistics?.newUsersPrevious}
      />
    } else {
      return (
        <NoChartData text={'No data'} />
      )
    }
  }

  return renderComponent()
}

const mapStateToProps = (state: { usersStatistics: any }) => ({
  usersStatistics: state.usersStatistics
})

export default connect(mapStateToProps)(NewUsersCountChart)
