import { CLEAR_REPEATED_USERS, SAVE_REPEATED_USERS } from '../actions/repeatedUsers'

const repeatedUsers = (state = null, action) => {
  if (action.type === SAVE_REPEATED_USERS) {
    return action.stats
  } else if (action.type === CLEAR_REPEATED_USERS) {
    return null
  }
  return state
}

export default repeatedUsers
