import { store } from '../../../index'

export const SAVE_CHATS_STATISTICS = 'SAVE_CHATS_STATISTICS'
export const CLEAR_CHATS_STATISTICS = 'CLEAR_CHATS_STATISTICS'

export const saveChatsStatistics = stats => {
  store.dispatch({
    type: SAVE_CHATS_STATISTICS,
    stats,
  })
}

export const clearChatsStatistics = () => {
  store.dispatch({ type: CLEAR_CHATS_STATISTICS})
}
