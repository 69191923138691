export const PRIMARY = 'primary'
// https://colors.artyclick.com/color-name-finder/
export const KLEIN_BLUE = '#0030A0'
export const CAMARONE_GREEN = '#006401'
export const GEM_BLUE = '#3B17A2'
export const CINNAMON_YELLOW = '#7D4400'
export const TAMARILLO_RED = '#A21010'
export const SPUN_PEARL_GREY = '#B0B2C0'
export const PERMISSION_RED = '#ff624c'
export const WHITE = '#ffffff'
export const RIBBON_BLUE = '#1658F3'
export const DULL_LAVANDER_BLUE = '#9bb3e4'
export const DANUBE_BLUE = '#547ed0'
export const CORNFLOWER_BLUE = '#5A98F7'
export const BLUE = '#0000FF'
export const COMET_GREY = '#616581'
export const SELAGO_BLUE = '#f3f7fe'
export const SHARK_BLACK = '#212529'
export const ATHENS_GRAY = '#fafafb'
export const MISCHKA_GRAY = '#D7D8DF'
export const OXFORD_BLUE = '#354052'
export const FIORD_BLUE = '#3a3f62'
export const ATHENS_GRAY_DARK = '#EBEBEF'
export const SOLITUDE = '#dfecff'
export const MALACHITE_GREEN = '#11C314'
export const ROYAL_BLUE = '#3a71f1'
export const GHOST_GRAY = '#C7CAD6'
export const SELAGO_GRAY = '#F7FAFE'
export const HAWKES_BLUE = '#EEF5FE'
export const PERIWINKLE = '#CADFFF'
export const MINE_SHAFT = '#393939'
export const PALE_SKY = '#6D7686'
export const EGYPTIAN_BLUE = '#0D3CAB'
export const PEPPERMINT = '#e5f8e2'
export const LIGHT_PEACH = '#ffd8aa'
export const TROPICAL_BLUE = '#d4e1ff'
export const HALF_DUTCH_WHITE = '#fff8db'
export const PEACH_SCHNAPPS = '#ffdcd8'
export const CADET_BLUE = '#acb0c0'
export const BRANDEIS_BLUE = '#0d6efd'
export const MEDIUM_GREEN = '#20b038'
export const RIVER_BED = '#464960'
export const JORDY_BLUE = '#8EB0F8'
export const GRAY = '#808080'
export const DEEP_SKY_BLUE = '#0084FF'
export const MERCURY = '#0067A5'
export const CYAN_BLUE = '#0560A9'
export const PASTEL_ORANGE = '#FF974C'
export const BLUEBERRY_BLUE = '#1146C2'
export const YELLOW_ORANGE = '#F3B116'
export const PLATINUM = '#E5E5E5'
export const GAINSBORO = '#DDDDDD'
export const EGG_WHITE = '#FEF2BD'
