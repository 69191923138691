import styled from 'styled-components'

const QuickReplyList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-bottom: 2px;
  margin: 8px 7px 4px 25%;
`

export { QuickReplyList }
