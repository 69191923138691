/**
 * Created by serhiy on 06.03.18.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.defaults.textGray,
    marginLeft: theme.spacing.unit * 2.5,
  },
  page: {
    color: 'var(--color-button-primary)',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'var(--color-hover-overlay-primary)'
    }
  },
  icon: {
    color: 'var(--color-button-primary)',
    '&:hover': {
      backgroundColor: 'var(--color-hover-overlay-primary)'
    }
  },
})

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0)
  }

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1)
  }

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1)
  }

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    )
  }

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props

    return (
      <div className={classes.root}>
        {count > 0 && (
          <span style={{ marginRight: 30 }}>
            {page * rowsPerPage + 1} -{' '}
            {count > (page + 1) * rowsPerPage
              ? (page + 1) * rowsPerPage
              : count}{' '}
            of {count}
          </span>
        )}

        <IconButton
          color="primary"
          className={classes.icon}
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          color="primary"
          className={classes.icon}
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        {page > 2 && '...'}
        {page > 1 && (
          <IconButton
            color="primary"
            className={classes.page}
            onClick={event =>
              this.props.onChangePage(event, this.props.page - 2)
            }
            aria-label="current">
            {this.props.page - 1}
          </IconButton>
        )}
        {page > 0 && (
          <IconButton
            color="primary"
            className={classes.page}
            onClick={event =>
              this.props.onChangePage(event, this.props.page - 1)
            }
            aria-label="current">
            {this.props.page}
          </IconButton>
        )}
        <IconButton
          color="primary"
          aria-label="current"
          className={classes.page}
          style={{ fontWeight: 'bold' }}>
          {this.props.page + 1}
        </IconButton>
        {page < Math.ceil(count / rowsPerPage) - 1 && (
          <IconButton
            color="primary"
            className={classes.page}
            onClick={event =>
              this.props.onChangePage(event, this.props.page + 1)
            }
            aria-label="current">
            {this.props.page + 2}
          </IconButton>
        )}
        {page + 1 < Math.ceil(count / rowsPerPage) - 1 && (
          <IconButton
            color="primary"
            className={classes.page}
            onClick={event =>
              this.props.onChangePage(event, this.props.page + 2)
            }
            aria-label="current">
            {this.props.page + 3}
          </IconButton>
        )}
        {page + 1 < Math.ceil(count / rowsPerPage) - 1 && '...'}
        <IconButton
          color="primary"
          className={classes.icon}
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          color="primary"
          className={classes.icon}
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page">
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    )
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
)
