import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { PieChart } from 'react-chartkick'

import { noDataText } from '../../constants/chartsTexts'

import { DateControlContext } from 'contexts/DateControlContext'
import { loadReactionsStatistics } from '../../api/dashboard'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import NoChartData from 'uiKit/NoChartData'

import * as S from '../CircleChart/CircleChart.style'
import {getColorAnalytics} from '../../../../globalStylesHelper.js'

interface Props {
  reactionsStatistics: any
  botId: string
}

const ReactionsCircleChart: React.FC<Props> = ({ reactionsStatistics, botId }) => {
  const [loading, setLoading] = useState(false)
  const { startDate, endDate } = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    loadReactionsStatistics(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .finally(() => setLoading(false))
  }, [endDate])

  const data = useMemo(() => reactionsStatistics && ([
    ['Good', reactionsStatistics.good],
    ['Bad', reactionsStatistics.bad],
    ['Without', reactionsStatistics.without]
  ]), [reactionsStatistics])

  const RGB = getColorAnalytics()
  const colors = [`rgb(${RGB.R}, ${RGB.G}, ${RGB.B})`, '#b4735c', '#a89669', '#7c9b7f']

  const renderComponent = () => {
    if (loading) {
      return (
        <S.LoaderContainer>
          <LoaderSmall showLoader={true}/>
        </S.LoaderContainer>
      )
    } else if (reactionsStatistics) {
      return (
        <PieChart data={data} colors={colors} legend={'bottom'} />
      )
    } else {
      return (
        <NoChartData text={noDataText} />
      )
    }
  }

  return renderComponent()
}

const mapStateToProps = (state: { reactionsStatistics: any }) => ({
  reactionsStatistics: state.reactionsStatistics
})

export default connect(mapStateToProps)(ReactionsCircleChart)
