import React, { useContext } from 'react'
import moment from 'moment'

import DateRange from '../DateRange'
import { DateControlContext } from 'contexts/DateControlContext'

import * as S from './DateControl.styles'

export const DATE_PICKER_FREQUENCIES = {
  week: 'week',
  month: 'month',
  custom: 'custom',
}

export const DateControl = (): JSX.Element => {
  const { startDate, endDate, frequency, onChange } = useContext(DateControlContext)

  const handleChange = frequency => {
    let newStartDate, newEndDate
    switch (frequency) {
      case DATE_PICKER_FREQUENCIES.week: {
        newStartDate = moment().subtract(1, 'week').add(1, 'day')
        newEndDate = moment()
        break
      }
      case DATE_PICKER_FREQUENCIES.month: {
        newStartDate = moment().subtract(1, 'month').add(1, 'day')
        newEndDate = moment()
        break
      }
      case DATE_PICKER_FREQUENCIES.custom: {
        newStartDate = startDate
        newEndDate = endDate
        break
      }
    }

    onChange({ startDate: newStartDate, endDate: newEndDate, frequency })
  }

  const handleCustomDate = (startDate, endDate) => {
    onChange({ startDate, endDate, frequency: DATE_PICKER_FREQUENCIES.custom })
  }

  return (
    <S.DateControl>
      <S.Frequency>
        <S.FrequencyButton
          onClick={() => handleChange(DATE_PICKER_FREQUENCIES.week)}
          isSelected={frequency === DATE_PICKER_FREQUENCIES.week}>
          Last 7 days
        </S.FrequencyButton>
        <S.FrequencyButton
          onClick={() => handleChange(DATE_PICKER_FREQUENCIES.month)}
          isSelected={frequency === DATE_PICKER_FREQUENCIES.month}>
          Last 30 days
        </S.FrequencyButton>
        <S.FrequencyButton
          onClick={() => handleChange(DATE_PICKER_FREQUENCIES.custom)}
          isSelected={frequency === DATE_PICKER_FREQUENCIES.custom}>
          Custom
        </S.FrequencyButton>
      </S.Frequency>
      {frequency === DATE_PICKER_FREQUENCIES.custom && (
        <DateRange startDate={startDate} endDate={endDate} setDate={handleCustomDate} />
      )}
    </S.DateControl>
  )
}
