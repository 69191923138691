import classes from './styles.module.scss'
import React, { FC } from 'react'

interface Props {
  margin?: number
}

const Divider: FC<Props> = ({ margin }) => {
  return <div className={classes.divider} style={{ margin: `${margin}px 0` }} />
}

export default Divider
