import React from 'react'
import './Loader-small.css'
import {LoaderSmallImage} from './LoaderSmallImage'

const LoaderSmall = props => {
  return props.showLoader ? (
    <div className="Loader-small">
      <LoaderSmallImage/>
    </div>
  ) : (
    <div />
  )
}

export default LoaderSmall
