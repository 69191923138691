import React from 'react'
import { connect } from 'react-redux'

import DownloadButton from '../../../../uiKit/buttons/DownloadButton'

export const headers = [
  { label: 'With good reaction', key: 'good' },
  { label: 'With bad reaction', key: 'bad' },
  { label: 'Without reaction', key: 'without' }
]


interface Props {
  reactionsStatistics: any
}

const convertCircleChartDataCsv = data => {
  return [...[], data]
}

const ReactionsCircleChartButton: React.FC<Props> = ({ reactionsStatistics }) => {
  return (
    <DownloadButton
      csvData={reactionsStatistics}
      csvFilename={'reactions'}
      csvHeaders={headers}
      csvOnDownload={convertCircleChartDataCsv}
    />
  )
}

const mapStateToProps = state => ({
  reactionsStatistics: state.reactionsStatistics,
})

export default connect(mapStateToProps)(ReactionsCircleChartButton)
