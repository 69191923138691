import React, { useEffect, useMemo, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import 'react-chartjs-2'
import { connect } from 'react-redux'

import AreaChart from './components/AreaChart'
import AreaChartButton from './components/AreaChartButton'
import ChartContainer from '../../uiKit/ChartContainer'
import Headline from 'uiKit/texts/Headline'
import DateControl from 'uiKit/DateControlNew'
import { DateControlProvider } from 'contexts/DateControlContext'

import { isObjectEmpty } from '../../helpers/isObjectEmpty'

import * as S from './Dashboard.style'

import {
  areaUsersTooltipText, reactionsTooltipText, skillChartTooltipText,
} from './constants/chartsTexts'

import classes from './styles.module.scss'
import { usersChartDataType } from 'models/DashboardTypes'
import { setBrowserTabTitle } from '../../helpers/setBrowserTabTitle'
import SkillTypesCircleChart from './components/SkillTypesCircleChart'
import ReactionsCircleChart from './components/ReactionsCircleChart'
import ReactionsCircleChartButton from './components/ReactionsCircleChartButton'
import SkillTypesCircleChartButton from './components/SkillTypesCircleChartButton'
import AverageNumberOfMessagesCountChart from './components/AverageNumberOfMessagesCountChart'
import TopUsersChart from './components/TopUsersChart'
import NewUsersCountChart from './components/NewUsersCountChart'
import TotalUsersCountChart from './components/TotalUsersCountChart'

interface Props {
  usersStatistics: usersChartDataType
  chatsStatistics: usersChartDataType
  reactionsStatistics
  skillTypesStatistics
  repeatedUsers
  avgNumberOfMessages
  topUsersStatistics
  supportStatistics: any
  popularHoursStatistics: any
  match: any
  activeBot: any
}

const Dashboard: React.FC<Props> = ({
  usersStatistics,
  chatsStatistics,
  supportStatistics,
  reactionsStatistics,
  skillTypesStatistics,
  repeatedUsers,
  avgNumberOfMessages,
  topUsersStatistics,
  popularHoursStatistics,
  match,
  activeBot,
}) => {
  const { botId } = match.params
  const containerRef = useRef(null)

  useEffect(() => {
    containerRef.current.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setBrowserTabTitle(`${activeBot?.name} - Dashboard`)
  }, [activeBot])

  const usersPlatformsNumber = useMemo(() => {
    if (usersStatistics?.points && !isObjectEmpty(usersStatistics?.points)) {
      return Object.values(usersStatistics?.points)[0]?.map(({ platform }) => platform)?.length > 2
    }
  }, [usersStatistics?.points])

  const activeUsersNumber = useMemo(() => {
    if (usersStatistics?.usersBetweenDates && !isObjectEmpty(usersStatistics?.usersBetweenDates)) {
      return Object.values(usersStatistics?.usersBetweenDates).reduce((acc, count) => acc + count, 0)
    }
    return 0
  }, [usersStatistics?.usersBetweenDates])

  return (
    <div className={classes.container} ref={containerRef}>
      <DateControlProvider>
        <S.HeadlineWrap>
          <Headline title="Dashboard" />
          <DateControl />
        </S.HeadlineWrap>
        <>
          <div className={classes.row}>
            <ChartContainer
              title="Active users"
              totalTitle={'Total users'}
              newTitle={'New users'}
              tooltipText={areaUsersTooltipText}
              newUsers={!usersPlatformsNumber && usersStatistics?.newUsers}
              activeUsers={!usersPlatformsNumber && activeUsersNumber}
              button={usersStatistics && <AreaChartButton />}>
              <AreaChart isMultipleChannels={!!usersPlatformsNumber} botId={botId} />
            </ChartContainer>
            <div className={classes.statsVerticalRow}>
              <NewUsersCountChart botId={botId} newUsers={!usersPlatformsNumber && usersStatistics?.newUsers} />
              <TotalUsersCountChart botId={botId} usersStatistics={!usersPlatformsNumber && activeUsersNumber} />
              <AverageNumberOfMessagesCountChart botId={botId} />
            </div>
          </div>

          <div className={classes.row}>
            <TopUsersChart botId={botId}/>
          </div>

          <div className={classes.row}>
            <ChartContainer
              title="Knowledge Usage"
              tooltipText={skillChartTooltipText}
              button={skillTypesStatistics && <SkillTypesCircleChartButton />}>
              <SkillTypesCircleChart botId={botId} />
            </ChartContainer>

            <ChartContainer
              title="Reactions"
              tooltipText={reactionsTooltipText}
              button={reactionsStatistics && <ReactionsCircleChartButton />}>
              <ReactionsCircleChart botId={botId} />
            </ChartContainer>
          </div>
        </>
      </DateControlProvider>
    </div>
  )
}

const mapStateToProps = (state: {
  usersStatistics: usersChartDataType
  chatsStatistics: usersChartDataType
  reactionsStatistics
  skillTypesStatistics
  repeatedUsers
  avgNumberOfMessages,
  topUsersStatistics,
  supportStatistics
  popularHoursStatistics
  activeBot
}) => ({
  usersStatistics: state.usersStatistics,
  chatsStatistics: state.chatsStatistics,
  reactionsStatistics: state.reactionsStatistics,
  skillTypesStatistics: state.skillTypesStatistics,
  repeatedUsers: state.repeatedUsers,
  avgNumberOfMessages: state.avgNumberOfMessages,
  topUsersStatistics: state.topUsersStatistics,
  supportStatistics: state.supportStatistics,
  popularHoursStatistics: state.popularHoursStatistics,
  activeBot: state.activeBot,
})

export default withRouter(connect(mapStateToProps)(Dashboard))
