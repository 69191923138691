import styled from 'styled-components'

const TextMessageButton = styled.div<{ highlight: boolean }>`
  border: 1px solid ${({ highlight }) => (highlight ? 'var(--color-general-ui)' :
    'var(--color-pressed-overlay-primary)')};
  border-radius: 8px;
  height: fit-content;
`

const ChatBubble = styled.span<{ isRtl: boolean }>`
  padding: 8px 16px;
  font-size: 14px;
  background: var(--color-table-row-primary);
  display: inline-block;
  width: 300px;
  cursor: pointer;
  direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
`

export { TextMessageButton, ChatBubble }
