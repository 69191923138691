import {
  SET_ATTRIBUTES,
  ADD_ATTRIBUTE,
  CLEAR_ATTRIBUTES,
} from '../actions/attributes'

const attributes = (state = [], { type, payload }) => {
  switch (type) {
  case SET_ATTRIBUTES:
    return [...payload.attributes]
  case ADD_ATTRIBUTE:
    return [...state, payload.attribute]
  case CLEAR_ATTRIBUTES:
    return []
  default:
    return state
  }
}

export default attributes
