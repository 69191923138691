import { SET_FUNNEL, CLEAR_STATE } from '../actions/funnel'

const funnels = (state = null, { payload, type }) => {
  switch (type) {
  case SET_FUNNEL:
    return payload.funnel
  case CLEAR_STATE:
    return null
  default:
    return state
  }
}

export default funnels
