import React, { FC, ChangeEvent, useRef, useState, useEffect } from 'react'

import * as S from './AutoResizingTextarea.style'

interface TextareaProps {
  minHeight: number
  maxHeight: number
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => Promise<void>
  isError: boolean
  value?: string
  [x: string]: any // All other props
}

const AutoResizingTextarea: FC<TextareaProps> = ({ minHeight, maxHeight, onChange, isError, value, ...htmlProps }) => {
  const [maxHeightReached, setMaxHeightReached] = useState(false)
  const textareaRef = useRef(null)

  useEffect(() => {
    setMaxHeightReached(textareaRef.current.scrollHeight > maxHeight)
  }, [value])

  const handleChange = event => {
    onChange(event)
    setMaxHeightReached(textareaRef.current.scrollHeight > maxHeight)
  }

  return (
    <S.GrowWrap data-replicated-value={value} maxHeight={maxHeight}>
      <S.Textarea
        ref={textareaRef}
        onChange={handleChange}
        minHeight={minHeight}
        maxHeight={maxHeight}
        isError={isError}
        showScroll={maxHeightReached}
        value={value}
        {...htmlProps}></S.Textarea>
      <S.GrowWrapAfter maxHeight={maxHeight}>{value}</S.GrowWrapAfter>
    </S.GrowWrap>
  )
}

export default AutoResizingTextarea
