import React from 'react'

export class Prev extends React.Component {
  render() {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M11.6667 15L12.8417 13.825L9.02501 10L12.8417 6.175L11.6667 5L6.66668 10L11.6667 15Z"
          fill="black"
        />
      </svg>
    )
  }
}
