import styled from 'styled-components'

const Title = styled.div`
  height: 32px;
  color: var(--color-spun-pearl-grey);
  padding: 8px 12px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
`

const LoadMoreButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadMoreButton = styled.button`
  font-size: 12px;
  border: 1px solid var(--color-button-primary);
  color: var(--color-button-primary);
  background: var(--color-white);
  border-radius: 10px;
  padding: 4px 12px;
  box-shadow: none;
  margin: 10px 0px;
  &:hover {
    background: var(--color-white);
  }
`

export { Title, LoadMoreButtonWrapper, LoadMoreButton }
