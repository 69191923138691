import { SAVE_REACTIONS_STATISTICS, CLEAR_REACTIONS_STATISTICS } from '../actions/reactionsStatistics'

const reactionsStatistics = (state = null, action) => {
  if (action.type === SAVE_REACTIONS_STATISTICS) {
    return action.stats
  } else if (action.type === CLEAR_REACTIONS_STATISTICS) {
    return null
  }
  return state
}

export default reactionsStatistics
