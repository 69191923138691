export const styles = () => ({
  arrow: {
    marginLeft: 10
  },
})

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: 210,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'var(--color-button-primary)',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'var(--color-button-primary)',
    display: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: 'var(--color-button-primary)',
    },
  }),
  option: (provided, state) => {
    return {
      ...provided,
      fontSize: 14,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? 'var(--color-hover-overlay-primary)' : 'var(--color-white)',
      color: 'var(--color-text-primary)',
      padding: '8px 4px',
      '&:hover': {
        fontSize: 14,
        backgroundColor: state.isFocused ? 'var(--color-hover-overlay-primary)' : 'var(--color-white)',
        color: 'var(--color-text-primary)',
      },
    }
  },
}
