import { store } from '../../../index'

export const SET_FUNNEL = 'SET_FUNNEL'
export const CLEAR_STATE = 'CLEAR_STATE'

export const setFunnel = funnel => {
  store.dispatch({
    type: SET_FUNNEL,
    payload: {
      funnel,
    },
  })
}

export const clearFunnelState = () => {
  store.dispatch({
    type: CLEAR_STATE
  })
}
