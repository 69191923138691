import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import { styles } from './styles'
import { UserMenu as View } from './UserMenu'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  adminUser: state.adminUser,
  accountSettings: state.account,
})

export const UserMenu = withRouter(
  withStyles(styles)(connect(mapStateToProps)(View)),
)
