import React from 'react'
import ReactPlayer from 'react-player'

interface Props {
  url: string
}

const VideoMessage: React.FC<Props> = props => {
  const { url } = props

  return (
    <div>
      <ReactPlayer width="328px" height="150px" url={url} controls />
    </div>
  )
}

export default VideoMessage
