import React from 'react'

export class Next extends React.Component {
  render() {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M8.33332 5L7.15833 6.175L10.975 10L7.15833 13.825L8.33332 15L13.3333 10L8.33332 5Z"
          fill="black"
        />
      </svg>
    )
  }
}
