import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import { styles } from './styles'
import { AccountSettingsForm as View } from './AccountSettingsForm'

const mapStateToProps = state => ({
  adminUser: state.adminUser,
  accountSettings: state.account,
})

export const AccountSettingsForm = withStyles(styles)(
  connect(mapStateToProps)(View),
)
