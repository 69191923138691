import { createMuiTheme } from '@material-ui/core/'
import {
  RIBBON_BLUE, CORNFLOWER_BLUE,
  JORDY_BLUE,
  GRAY, DEEP_SKY_BLUE, ROYAL_BLUE, MERCURY, PASTEL_ORANGE,
  BLUEBERRY_BLUE, YELLOW_ORANGE, PLATINUM,
  GAINSBORO
} from 'constants/colors'

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: CORNFLOWER_BLUE,
      medium: BLUEBERRY_BLUE,
      main: RIBBON_BLUE,
    },
  },
  defaults: {
    buttonText: 'var(--color-white)',
    buttonBackground: RIBBON_BLUE,
    buttonBackgroundLight: CORNFLOWER_BLUE,
    headerText: 'var(--color-text-primary)',
    primaryText: 'var(--color-text-secondary)',
    grayText: 'var(--color-spun-pearl-grey)',
    background: 'var(--color-selago-grey)',
    gray: 'var(--color-ghost-grey)',
    contrastText: 'var(--color-white)',
    error: 'var(--color-coral-red)',
    iconsGray: 'var(--color-mischka-grey)',
    success: 'var(--color-malachite-green)',
    warning: PASTEL_ORANGE,
    text: 'var(--color-text-secondary)',
    grayColor: 'var(--color-mischka-grey)',
    textHeader: 'var(--color-text-primary)',
    textGray: 'var(--color-spun-pearl-grey)',
    pieChartColors: {
      chatbot: RIBBON_BLUE,
      support: YELLOW_ORANGE,
    },
  },
  menu: {
    tabList: () => 'linear-gradient(359.25deg, ' + theme.defaults.buttonBackgroundLight +
      ' 49.98%, ' + theme.defaults.buttonBackground + ' 149.98%)',
    tabListItemActive: 'rgba(19,69,186,0.5)',
    tabListItemHover: 'rgba(19,69,186,0.5)',
    tabListIcon: JORDY_BLUE,
    tabListIconActive: 'var(--color-white)',
    helpIcon: 'var(--color-white)',
    helpText: 'var(--color-white)',
    background: () => theme.defaults.background,
    root: () => theme.defaults.background,
    toolbar: 'var(--color-white)',
    homeButtonText: () => theme.palette.primary.main,
    testButton: () => 'linear-gradient(285.2deg, ' + theme.defaults.buttonBackgroundLight +
      ' -35.9%, ' + theme.defaults.buttonBackground + ' 61.17%)',
    testButtonText: () => theme.defaults.contrastText,
  },
  uikit: {
    paragraph: {
      text: 'var(--color-text-secondary)',
    },
  },
  tabs: {
    login: {
      buttonText: () => theme.defaults.buttonText,
      buttonBackground: () => theme.defaults.buttonBackground,
      imageBackground: () => theme.defaults.background,
      formTitle: () => theme.defaults.headerText,
      eyeIcon: () => theme.palette.primary.main,
      input: {
        border: 'var(--color-mischka-grey)',
        borderFocus: () => theme.palette.primary.main,
        borderError: 'var(--color-coral-red)',
        placeholderText: () => theme.defaults.grayText,
        label: () => theme.defaults.primaryText,
        errorLabel: 'var(--color-coral-red)',
      },
      forgotPassword: {
        formTitle: () => theme.defaults.headerText,
        formSubtitle: () => theme.defaults.primaryText,
        noteText: () => theme.defaults.primaryText,
        link: GRAY,
      },
      afterSighUp: {
        title: () => theme.defaults.headerText,
        text: 'var(--color-text-secondary)',
        emailText: () => theme.palette.primary.main,
        background: () => theme.defaults.background,
        containerBackground: 'var(--color-white)',
      },
      unavailableSmall: {
        titleClassText: 'var(--color-text-primary)',
        paragraphClassText: () => theme.defaults.primaryText,
        buttonText: 'var(--color-white)',
        button: () => theme.defaults.buttonBackground,
      }
    },
    home: {
      headerBackground: () => theme.defaults.contrastText,
      background: () => theme.defaults.background,
      text: () => theme.defaults.headerText,
      buttonText: () => theme.palette.primary.main,
      botPlate: {
        menuBorder: 'var(--color-mischka-grey)',
        menuButtonText: 'var(--color-text-primary)',
        menuButtonHover: 'rgba(90, 152, 247, 0.1)',
        botBackground: 'var(--color-white)',
        botText: 'var(--color-text-primary)',
        createBotBackground: () => 'linear-gradient(325.02deg, ' + theme.defaults.buttonBackgroundLight +
          ' 0%, ' + theme.defaults.buttonBackground + ' 100%)',
        createBotText: () => theme.defaults.contrastText,
        createBotErrorText: () => theme.defaults.error,
      },
    },
    accountSettings: {
      background: () => theme.defaults.background,
      titleText: 'var(--color-oxford-blue)',
      inputBackground: 'var(--color-white)',
    },
    dateButton: {
      color: 'var(--color-white)',
      colorSelected: () => theme.defaults.buttonBackgroundLight,
      border: 'var(--color-issabeline-grey)',
      borderSelected: () => theme.defaults.buttonBackgroundLight,
      text: () => theme.defaults.primaryText,
      textSelected: 'var(--color-white)',
    },
    flows: {
      newFlowBackground: 'var(--color-white)',
      flowListItems: 'var(--color-white)',
      flowListItemsText: () => theme.defaults.headerText,
      flowListItemsSubtitle: () => theme.defaults.primaryText,
      newFlowButton: () => 'linear-gradient(312.17deg, ' + theme.defaults.buttonBackgroundLight +
        ' 3.33%, ' + theme.defaults.buttonBackground + ' 96.71%)',
      newFlowButtonPlusIcon: 'var(--color-white)',
      newFlowText: 'var(--color-text-primary)',
      deleteFlowButton: 'var(--color-white)',
      deleteFlowButtonBorder: 'var(--color-issabeline-grey)',
      deleteFlowButtonIcon: 'var(--color-spun-pearl-grey)',
      headerText: () => theme.defaults.headerText,
      headerFontFamily: 'Lato, sans-serif',
      searchBorderColor: 'var(--color-ghost-grey)',
      searchIcon: 'var(--color-mischka-grey)',
    },
    atoms: {
      newAtomText: () => theme.palette.primary.main,
      newAtomBorder: () => theme.palette.primary.main,
      newAtomPlusIcon: () => theme.palette.primary.main,
      newAtomBackground: 'var(--color-white)',
      newAtomInputBorder: 'var(--color-coral-red)',
      newAtomInputBorderError: 'var(--color-ghost-grey)',
      border: 'var(--color-issabeline-grey)',
      background: 'var(--color-white)',
      text: () => theme.defaults.primaryText,
      textHover: () => theme.defaults.primaryText,
      activeText: 'var(--color-white)',
      activeBackground: CORNFLOWER_BLUE,
      activeBorder: CORNFLOWER_BLUE,
      activeDeleteIcon: 'var(--color-white)',
    },
    atomEditor: {
      saveAtomButton: () => 'linear-gradient(285.2deg, ' + theme.defaults.buttonBackgroundLight +
        ' -35.9%, ' + theme.defaults.buttonBackground + ' 61.17%)',
      background: 'var(--color-white)',
      elementIcon: CORNFLOWER_BLUE,
      textMessage: {
        button: () => theme.defaults.buttonBackgroundLight,
        buttonHover: 'var(--color-white)',
        buttonText: 'var(--color-white)',
        buttonHoverText: DEEP_SKY_BLUE,
      },
      genericTemplate: {
        buttonText: DEEP_SKY_BLUE,
      },
      quickReply: {
        ovalIcon: RIBBON_BLUE,
        border: ROYAL_BLUE,
        text: ROYAL_BLUE,
        payloadText: GRAY,
        addNewBorder: ROYAL_BLUE,
        addNewText: RIBBON_BLUE,
      },
    },
    nlp: {
      menuButtonBackground: RIBBON_BLUE,
      menuButtonBackgroundHover: 'var(--color-text-placeholder)',
      menuButtonText: 'var(--color-white)',
      subHeaderText: 'var(--color-text-secondary)',
      training: {
        menuItemBackground: 'var(--color-coral-red)',
        buttonText: RIBBON_BLUE,
        buttonBackground: 'var(--color-white)',
        buttonBackgroundHover: 'var(--color-white)',
        mainSelectedBackground: 'var(--color-white)',
        selectItemBorderRight: 'var(--color-issabeline-grey)',
        descText: 'var(--color-text-primary)',
        selectIconBackground: 'var(--color-coral-red)',
        trainedIconBackground: 'var(--color-malachite-green)',
        trainedIconBorder: 'var(--color-malachite-green)',
        leftTabBackground: CORNFLOWER_BLUE,
        leftTabText: 'var(--color-white)',
        rightTabBackground: 'var(--color-white)',
        rightTabText: 'var(--color-text-secondary)',
        titleText: 'var(--color-text-primary)',
        activeNlpBackground: CORNFLOWER_BLUE,
        activeNlpText: 'var(--color-white)',
        defaultNlpBackground: 'var(--color-white)',
        defaultNlpText: 'var(--color-text-secondary)',
        filterText: 'var(--color-text-primary)',
      },
    },
    support: {
      tabBackground: 'var(--color-white)',
      tabBackgroundActive: CORNFLOWER_BLUE,
      tabText: 'var(--color-text-secondary)',
      tabTextActive: 'var(--color-white)',
      assignToMeButton: RIBBON_BLUE,
      assignToMeButtonText: 'var(--color-white)',
      assignToButtonBorder: RIBBON_BLUE,
      assignToButton: 'var(--color-white)',
      assignToButtonText: RIBBON_BLUE,
      atomButtons: {
        text: RIBBON_BLUE,
        background: 'var(--color-white)',
        backgroundPressed: 'var(--color-periwinkle)',
        border: PLATINUM,
        borderPressed: MERCURY,
        tooltipText: 'var(--color-white)',
        tooltipBackground: 'var(--color-mine-shaft)',
      },
      closeButtonBorder: RIBBON_BLUE,
      closeButtonBackground: 'var(--color-white)',
      closeButtonText: RIBBON_BLUE,
      closeButtonBorderDisabled: RIBBON_BLUE,
      postbackModal: {
        popoverBackgroundColor: 'var(--color-white)',
        atomNameBorder: 'var(--color-issabeline-grey)',
        menuTitleText: 'var(--color-oxford-blue)',
        primaryText: 'var(--color-text-secondary)',
        buttonType: 'var(--color-shark-black)',
        secondaryText: 'var(--color-white)',
        tooltipBackground: 'var(--color-mine-shaft)',
        tooltipText: 'var(--color-white)',
        attributeNameBorder: CORNFLOWER_BLUE,
        attributeNameBackground: CORNFLOWER_BLUE,
        attributeNameScrollbar: GAINSBORO,
        attributeValueBorder: 'var(--color-issabeline-grey)',
        checkBox: {
          checkedIcon: CORNFLOWER_BLUE,
          notCheckedIcon: 'var(--color-white)',
          checkedMarkIcon: 'var(--color-white)',
          notCheckedMarkIcon: 'var(--color-white)',
          checkedBorder: CORNFLOWER_BLUE,
          notCheckedBorder: CORNFLOWER_BLUE,
        },
      },
      quickReply: {
        background: 'var(--color-white)',
        backgroundSubmit: RIBBON_BLUE,
        backgroundPressed: 'var(--color-periwinkle)',
        border: RIBBON_BLUE,
        borderSubmit: RIBBON_BLUE,
        borderPressed: 'var(--color-periwinkle)',
        text: RIBBON_BLUE,
        textSubmit: 'var(--color-white)',
      },
    },
  },
})
